<ion-row *ngIf="loading">
    <ion-col size="12" class="ion-text-center">
        <ion-spinner color="primary"></ion-spinner>
    </ion-col>
</ion-row>
<ion-row *ngIf="bootcamp?.id && customertzdisplay">
    <ion-col size="12" *ngIf="selectedworkshop" class="ion-text-center">
        <ion-row>
            <ion-col size="8" class="ion-text-left ion-align-self-center">
                <p class="workshop-title">Your workshop is on <span class="{{selectedworkshop.timeslot.days}}-color">{{selectedworkshop.timeslot.days}}</span> {{selectedworkshop.timeslot.timeslot | lowercase}}s</p>
                <p class="workshop-time">from <b>{{selectedworkshop.timeslot.starttime}}</b> to <b>{{selectedworkshop.timeslot.endtime}}</b> your time ({{this.customertzdisplay}})</p>
                <!-- <p class="change-workshop-text hover href" (click)="showHideSelection()">Change workshop day/time?
                    <ng-container *ngIf="!showSelection"><ion-icon name="chevron-down-outline" color="blue" style="padding-left: 4px"></ion-icon></ng-container>
                    <ng-container *ngIf="showSelection"><ion-icon name="chevron-up-outline" color="blue" style="padding-left: 4px"></ion-icon></ng-container>
                </p> -->
            </ion-col>
            <ion-col size="4" class="ion-text-center ion-align-self-center ion-padding-horizontal">
                <img src="/assets/imgs/business/illustration-online-workshop-screen-only.svg" alt="Saturday or Sunday Workshops">
            </ion-col>
        </ion-row>
    </ion-col>
    <ion-col size="12" *ngIf="!selectedworkshop" class="ion-text-center">
        <ion-row>
            <ion-col size="8" class="ion-text-left ion-align-self-center">
                <p class="workshop-title">Please select the day/time for your weekly workshops below</p>
            </ion-col>
            <ion-col size="4" class="ion-text-center ion-align-self-center ion-padding-horizontal">
                <img src="/assets/imgs/business/illustration-online-workshop-screen-only.svg" alt="Saturday or Sunday Workshops">
            </ion-col>
        </ion-row>
    </ion-col>
    <ion-col size="12" class="workshop-drawer-wrap" [ngClass]="(showSelection || !selectedworkshop) ? 'showSelection' : 'hide'">
        <ion-row>
            <ng-container *ngTemplateOutlet="workshopdayslist"></ng-container>
        </ion-row>
    </ion-col>
</ion-row>
<ion-row *ngIf="!bootcamp?.id && customertzdisplay">
    <ng-container *ngTemplateOutlet="workshopdayslist"></ng-container>
</ion-row>
<ng-template #workshopdayslist>
    <ion-col *ngIf="bootcamp?.id" size="12" class="ion-padding-bottom">
        <p class="workshop-time">Click to select another day/time for your weekly workshops:</p>
    </ion-col>
    <ng-container *ngIf="onlineworkshops.length <= 2">
        <ion-col size-lg="6" size="12" *ngFor="let day of onlineworkshops" style="padding: 0 4px">
            <ion-row>
                <ion-col size="12" class="workshopday-font ion-padding-bottom" [ngClass]="!bootcamp?.id ? 'ion-text-center' : 'workshop-time-center'">
                    <p><span class="{{day.workshopday}}-color">{{day.workshopday}}</span> Workshops</p>
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let workshop of day.timeslots">
                <ion-col size="12" class="ion-text-center hover ion-padding-bottom" (click)="selectWorkshopTimeZone(day.workshopday, workshop)">
                    <ion-row class="workshop-option" [ngClass]="(workshop.selectedworkshop && bootcamp?.id) ? 'workshop-border ' + bootcamp.name + 'Border' : ''">
                        <ion-col size="2" class="ion-text-center ion-align-self-center workshop-option-text-icon">
                            <div *ngIf="!workshop.selectedworkshop || !bootcamp?.id" class="step-number">{{workshop.index}}</div>
                            <fa-icon *ngIf="workshop.selectedworkshop && bootcamp?.id" class="workshop-check {{bootcamp.name}}Font" [icon]="faCheck"></fa-icon>
                        </ion-col>
                        <ion-col size="10" class="ion-text-left ion-align-self-center workshop-option-text-text">From <b>{{workshop.timeslot.starttime}}</b> to <b>{{workshop.timeslot.endtime}}</b> your time<br><span style="font-size: smaller;">({{this.customertzdisplay}})</span></ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-col>
    </ng-container>
    <ng-container *ngIf="onlineworkshops.length === 3 && completebootcamp">
        <ion-col size="12" *ngFor="let day of onlineworkshops" style="padding: 0 4px">
            <ion-row>
                <ion-col size="12" class="workshopday-font ion-padding-bottom">
                    <p><span class="{{day.workshopday}}-color">{{day.workshopday}}</span> Workshops</p>
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let workshop of day.timeslots">
                <ion-col size="12" class="ion-text-center hover ion-padding-bottom" (click)="selectWorkshopTimeZone(day.workshopday, workshop)">
                    <ion-row class="workshop-option" [ngClass]="(workshop.selectedworkshop && bootcamp?.id) ? 'workshop-border ' + bootcamp.name + 'Border' : ''">
                        <ion-col size="2" class="ion-text-center ion-align-self-center workshop-option-text-icon">
                            <div *ngIf="!workshop.selectedworkshop || !bootcamp?.id" class="step-number">{{workshop.index}}</div>
                            <fa-icon *ngIf="workshop.selectedworkshop && bootcamp?.id" class="workshop-check {{bootcamp.name}}Font" [icon]="faCheck"></fa-icon>
                        </ion-col>
                        <ion-col size="10" class="ion-text-left ion-align-self-center workshop-option-text-text">
                            From&nbsp;<b>{{workshop.timeslot.starttime}}</b> to <b>{{workshop.timeslot.endtime}}</b> your time&nbsp;<span style="font-size: smaller;">({{this.customertzdisplay}})</span>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-col>
    </ng-container>
    <ng-container *ngIf="onlineworkshops.length === 3 && !completebootcamp">
        <ion-col size-lg="4" size="12" *ngFor="let day of onlineworkshops" style="padding: 0 4px">
            <ion-row>
                <ion-col size="12" class="workshopday-font ion-padding-bottom" [ngClass]="!bootcamp?.id ? 'ion-text-center' : 'workshop-time-center'">
                    <p><span class="{{day.workshopday}}-color">{{day.workshopday}}</span><ng-container *ngIf="windowRef.width() > 992"><br></ng-container> Workshops</p>
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let workshop of day.timeslots">
                <ion-col size="12" class="ion-text-center hover ion-padding-bottom" (click)="selectWorkshopTimeZone(day.workshopday, workshop)">
                    <ion-row class="workshop-option" [ngClass]="(workshop.selectedworkshop && bootcamp?.id) ? 'workshop-border ' + bootcamp.name + 'Border' : ''">
                        <ion-col size="2" class="ion-text-center ion-align-self-center workshop-option-text-icon">
                            <div *ngIf="!workshop.selectedworkshop || !bootcamp?.id" class="step-number">{{workshop.index}}</div>
                            <fa-icon *ngIf="workshop.selectedworkshop && bootcamp?.id" class="workshop-check {{bootcamp.name}}Font" [icon]="faCheck"></fa-icon>
                        </ion-col>
                        <ion-col size="10" class="ion-text-left ion-align-self-center workshop-option-text-text">
                            From&nbsp;<b>{{workshop.timeslot.starttime}}</b> to <b>{{workshop.timeslot.endtime}}</b> your time&nbsp;<span style="font-size: smaller;">({{this.customertzdisplay}})</span>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-col>
    </ng-container>
</ng-template>