import { Injectable } from '@angular/core';
import { WindowrefService } from '../windowref/windowref.service';
import { SessionrecordingService } from '../sessionrecording/sessionrecording.service';
import { CookieService } from 'ngx-cookie';
import { FirestoreService } from '../firestore/firestore.service';
import { HttpClient } from '@angular/common/http';  
import { GeodataService } from '../geodata/geodata.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { firstValueFrom, map } from 'rxjs';

/*
  Generated class for the NucampanalyticsService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


@Injectable({providedIn: 'root'})
export class NucampanalyticsService {
  isRecording = false;
  gtmAddedToDom = false;
  addedToCart = false;
  geoLocation;
  getGeoLocationFetched = false;

  constructor(
    private windowRef: WindowrefService,
    private sessionRec: SessionrecordingService,
    private fb: FirestoreService,
    private geoData: GeodataService,
    private http: HttpClient,
    private cookieService: CookieService,
    private gtm: GoogleTagManagerService
    ) {

  }

  generateEventID() {
    return Math.random().toString(36).substring(2, 9).toUpperCase() + '-' + Math.random().toString(36).substring(2, 9).toUpperCase();
  }

  getCookie(key: string){
    return this.cookieService.get(key);
  }

  addGTMtoDOM() {
    // console.log("this.gtmAddedToDom = true");
    this.gtmAddedToDom = true;  
  }

  pushTagToDOM(payload) {
    if (this.gtmAddedToDom) return this.gtm.pushTag(payload).catch((err) => {
      // console.error("Client-side Pixel Error", err);
      Promise.resolve();
    });
    else {
      // console.error("gtmAddedToDom not yet added, not pushing this payload", payload);
      return Promise.resolve();
    }
  }

  setPayload(data, user?) {
    const eventID = this.generateEventID();
    const payload = {...data, eventID};
    if (user && user.uid) payload.external_id = user.uid;
    else if (user && user.id) payload.external_id = user.id;
    return payload;
  }

  async sendServerEvent(data, loggedUser) {
    const fbc = this.getCookie("_fbc");
    const fbp = this.getCookie("_fbp");
    const ttp = this.getCookie("_ttp");
    const ttclid = this.windowRef.queryParamMap("ttclid");

    const payload:any = {
      event_name: data.event_name,
      event_id: data.event_id,
      user_data: {
        client_user_agent: this.windowRef.nativeWindow.navigator.userAgent,
        callback: ttclid,
      },
      action_source: 'website',
      event_source_url: this.windowRef.nativeLocation.href,
      custom_data: data.custom_data,
    }
    if (this.geoLocation?.ip) {
      payload.user_data.client_ip_address = this.geoLocation.ip;
    }
    if (data.properties) {
      payload.properties = data.properties;
    }
    if (loggedUser) {
      if (loggedUser.email) payload.user_data.em = loggedUser.email;
      if (loggedUser.phoneId) {
        payload.user_data.ph = loggedUser.phoneId;
      } else if (loggedUser.phone) {
        payload.user_data.ph = loggedUser.phone;
      }
      if (loggedUser.lastname) payload.user_data.ln = loggedUser.lastname;
      if (loggedUser.firstname) payload.user_data.fn = loggedUser.firstname;
      if (loggedUser.location) payload.user_data.ct = loggedUser.location; // city
      if (loggedUser.state) payload.user_data.st = loggedUser.state; // state
      if (loggedUser.uid) {
        payload.user_data.external_id = loggedUser.uid;
      } else if (loggedUser.id) {
        payload.user_data.external_id = loggedUser.id;
      }
    }
    if (fbc) payload.user_data.fbc = fbc;
    if (fbp) payload.user_data.fbp = fbp;
    if (ttp) payload.user_data.ttp = ttp;
    // payload.recordall = true;
    console.log("Server Analytics Data", payload);
    await firstValueFrom(this.fb.post("/pixel/conversion", payload))
      .then((result) => console.log("Server-side Pixel Success", result))
      .catch((err) => {
        console.log("Server-side Pixel Error", err);
        return Promise.resolve();
      });
  }

  public getGeoData(user) {
    const data = {
      street: "",
      city: "",
      region: "",
      country: "",
      zipcode: ""
    }

    if (user && user.fulladdress && Array.isArray(user.fulladdress)) {
      const countryData = user.fulladdress.find((address) => address.types.includes("country"));
      const zipcodeData = user.fulladdress.find((address) => address.types.includes("postal_code"));
      const stateData = user.fulladdress.find((address) => address.types.includes("administrative_area_level_1"));
      const cityData = user.fulladdress.find((address) => address.types.includes("locality"));
      const streetPremiseData = user.fulladdress.find((address) => address.types.includes("premise"));
      const streetSubPremiseData = user.fulladdress.find((address) => address.types.includes("subpremise"));
      const streetNumberData = user.fulladdress.find((address) => address.types.includes("street_number"));
      const streetRouteData = user.fulladdress.find((address) => address.types.includes("route"));
      if (countryData) {
        data.country = countryData.short_name;
      }
      if (zipcodeData) {
        data.zipcode = zipcodeData.long_name;
      }
      if (stateData) {
        data.region = stateData.short_name;
      }
      if (cityData) {
        data.city = cityData.long_name;
      }
      if (streetNumberData) {
        data.street = data.street + " " + streetNumberData.long_name;
      }
      if (streetRouteData) {
        data.street = data.street + " " + streetRouteData.long_name;
      }
      if (streetPremiseData) {
        data.street = data.street + " " + streetPremiseData.long_name;
      }
      if (streetSubPremiseData) {
        data.street = data.street + " " + streetSubPremiseData.long_name;
      }
    }

    return data;
  }

  async sendClientEvent(data, loggedUser?){
    const payload = {...data};
    if (loggedUser) {
      if (loggedUser.email) payload.email = loggedUser.email;
      if (loggedUser.phoneId) {
        payload.phone_number = loggedUser.phoneId;
      } else if (loggedUser.phone) {
        payload.phone_number = loggedUser.phone;
      }
      if (loggedUser.lastname) payload.last_name = loggedUser.lastname;
      if (loggedUser.firstname) payload.first_name = loggedUser.firstname;
      if (loggedUser.location) payload.city = loggedUser.location;
      if (loggedUser.state) payload.region = loggedUser.state;
      
      const addressData = this.getGeoData(loggedUser);
      
      if (addressData.region) payload.region = addressData.region;
      if (addressData.city) payload.city = addressData.city;
      if (addressData.zipcode) payload.zipcode = addressData.zipcode;
      if (addressData.country) payload.country = addressData.country;
      if (addressData.street) payload.street = addressData.street;
    }
    // console.log("Client Analytics Data", payload);
    await this.pushTagToDOM(payload);
  }

  public async event(category, action, label) {
    const payLoad = this.setPayload(
      {
        'event': 'Interaction',
        'category': category,
        'action': action,
        'label': label
      }
    )
    await this.pushTagToDOM(payLoad);
  }

  // getIP() {
  //   if (!this.geoLocation || (this.geoLocation && !this.geoLocation.ip)) {
  //     firstValueFrom(this.http.get("https://api.ipify.org/?format=json").pipe(map((res:any) => {
  //       if (res && res.ip) {
  //         this.geoLocation = res;
  //         console.log("Get IP", this.geoLocation);
  //         const coma = res.ip.indexOf(",");
  //         if (coma !== -1) {
  //           this.geoLocation.ip = res.ip.substring(0, coma);
  //         } else {
  //           this.geoLocation.ip = res.ip;
  //         }
  //       }
  //     })));
  //   }
  // }

  async getGeoLocation() {
    // console.log("this.windowRef.isNucampSSRBrowser()", this.windowRef.isNucampSSRBrowser());
    if (!this.windowRef.isNucampSSRBrowser() && !this.getGeoLocationFetched && !this.geoLocation || (this.geoLocation && !this.geoLocation.location)) {
      this.getGeoLocationFetched = true;
      // await firstValueFrom(this.http.get("https://geo.ipify.org/api/v2/country,city?apiKey=at_8kIKFgWkrJ7dN4Z3N6jkLpBK6Djly").pipe(map((res:any) => {
      //   // console.log("Geo Location After", res);
      //   if (res && res.ip) {
      //     this.geoLocation = res;
      //     const coma = res.ip.indexOf(",");
      //     if (coma !== -1) {
      //       this.geoLocation.ip = res.ip.substring(0, coma);
      //     } else {
      //       this.geoLocation.ip = res.ip;
      //     }
      //   }
      // }))).catch((err) => null);
      await firstValueFrom(this.http.get("https://api.ipgeolocation.io/ipgeo?apiKey=4e398c32d6184ee89165758d4359198e").pipe(map((res:any) => {
        // console.log("Geo Location After", res);
        if (res && res.ip) {
          this.geoLocation = res;
          this.geoLocation.location = {
            lat: res.latitude,
            lng: res.longitude
          }
          const coma = res.ip.indexOf(",");
          if (coma !== -1) {
            this.geoLocation.ip = res.ip.substring(0, coma);
          } else {
            this.geoLocation.ip = res.ip;
          }
          // console.log("this.geoLocation.ip", this.geoLocation.ip);
        }
      }))).catch((err) => null);
    }
    return this.geoLocation;
  }

  public async browseWebsite(user?) {
    console.log("Browse Website");
    const clientPayload = this.setPayload({
      'event': 'browse',
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_name: 'browse',
        value: 0.1,
        currency: 'USD'
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
    // this.startRecording();
  }

  public async payInvoice(invoice, user?) {
    const clientPayload = this.setPayload({
      'event': 'payinvoice',
      'value': invoice.invoices.nucamp.totals.amountdue
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_name: 'payinvoice',
        invoiceamount: invoice.invoices.nucamp.totals.amountdue,
        currency: 'USD'
      }
    }
    // console.log(invoice, fbServerPayload);
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async browseInvoice(user?) {
    const clientPayload = this.setPayload({
      'event': 'browseinvoice'
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_name: 'browseinvoice',
        value: 5,
        currency: 'USD'
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async createCompanyProfile(user?) {
    const clientPayload = this.setPayload({
      'event': 'createcompany'
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_name: 'createcompany',
        value: 50,
        currency: 'USD'
      }
    }
    if ((user && !this.isNucampStaff(user)) || !user) {
      await Promise.all([
        this.sendClientEvent(clientPayload, user).catch(),
        this.sendServerEvent(fbServerPayload, user).catch()
      ]);
    }
  }

  public async browseJob(user?) {
    const clientPayload = this.setPayload({
      'event': 'browsejob'
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_name: 'browsejob',
        value: 0.1,
        currency: 'USD'
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async postJob(user?) {
    const clientPayload = this.setPayload({
      'event': 'postjob'
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_name: 'postjob',
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async startRecording() {
    const payLoad = this.setPayload({
      'event': 'start_recording'
    });
    if (!this.isRecording) {
      this.sessionRec.startRecording();
      await this.pushTagToDOM(payLoad)
      this.isRecording = true;
    }
  }

  public async startPixels() {
    const payLoad = this.setPayload({
      'event': 'start_pixel'
    });
    await this.pushTagToDOM(payLoad)
  }

  public async isNucampStaff(user?) {
    let isStaff = false;

    if (user && user.roles && Object.keys(user.roles).length > 1) isStaff = true;
    if (user && user.linkedInstructors && user.linkedInstructors.length > 0) isStaff = true;
    return isStaff;
 }

  public async AddtoCart(bootcamp, user?) {
    console.log("Add to Cart");
    if (!this.addedToCart) {
      this.addedToCart = true;

      const userCountry = await this.geoData.getsetFullAddressCountryCode(user)
        .catch((err) => null);
      const contentids = [bootcamp.id, `bootcamp-${bootcamp.bootcamp.id}`];
      if (userCountry) contentids.push(`country-${userCountry}`);
      const value = Math.round(bootcamp.cost.earlybird * 0.05); // pass the value of AddToCart as being 5% of the total tuition
  
      const clientPayload = this.setPayload({
        'event': 'addtocart_bootcamp',
        'bootcampid': bootcamp.bootcamp.id,
        'contentids': contentids,
        'bootcamptitle': bootcamp.title,
        'transactionTotal': value,
        'community': bootcamp.community.urlId,
        'tuition': value,
        'value': value,
        'items': [{
          item_id: bootcamp.id,
          item_name: bootcamp.title,
          item_category: bootcamp.title,
          item_variant: bootcamp.community.community.name + ", " + bootcamp.community.community.state,
          price: value,
          quantity: 1,
        }],
        'content_id': bootcamp.bootcamp.id,
        'quantity': 1,
        'price': value,
        'currency': 'USD'
      }, user);
      const fbServerPayload = {
        event_name: 'AddToCart',
        event_id: clientPayload.eventID,
        custom_data: {
          content_ids: contentids,
          content_category: 'Software &#x3E; Computer Software &#x3E; Educational Software',
          content_name: bootcamp.title,
          content_type: 'product',
          value: value,
          currency: 'USD'
        },
        properties: {
          currency: 'USD',
          value: value,
          description: bootcamp.title,
          contents: [{
            content_id: bootcamp.bootcamp.id,
            content_type: "product",
            content_category: "Technology",
            content_name: bootcamp.title,
            price: value,
            quantity: 1,
          }],
        }
      }
      if (user) {
        if (!user.state && bootcamp.community.community.state) user.state = bootcamp.community.community.state;
        if (!user.location && bootcamp.community.community.city) user.location = bootcamp.community.community.city;
      }
      this.windowRef.nucampTracking({content: bootcamp.id, campaign: "addtocart"});
      await Promise.all([
        this.sendClientEvent(clientPayload, user).catch(),
        this.sendServerEvent(fbServerPayload, user).catch()
      ]);
    }
  }

  public async contact(user?) {
    const clientPayload = this.setPayload({
      'event': 'contact'
    }, user);
    const fbServerPayload = {
      event_name: 'Contact',
      event_id: clientPayload.eventID,
      custom_data: {
        value: 30,
        currency: 'USD'
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async Login(provider, user?) {
    const clientPayload = this.setPayload({
      'event': 'user_login',
      'method': provider
    }, user);
    const fbServerPayload = {
      event_name: 'Contact',
      event_id: clientPayload.eventID,
      custom_data: {
        value: 30,
        currency: 'USD'
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async captureUserEmail(user, type: "business" | "consumer") {
    await this.browseWebsite(user);
    let clientPayload;
    let fbServerPayload;
    if (type === "consumer") {
      clientPayload = this.setPayload({
        'event': 'lead_email'
      }, user);
      fbServerPayload = {
        event_name: 'Lead',
        event_id: clientPayload.eventID,
        custom_data: {
          value: 30,
          currency: 'USD'
        }
      }
    } else if (type === "business") {
      clientPayload = this.setPayload({
        'event': 'business_lead_email',
      }, user);
      fbServerPayload = {
        event_name: 'Lead',
        event_id: clientPayload.eventID,
        custom_data: {
          value: 100,
          currency: 'USD'
        }
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async viewCommunity(community, user?){
    const clientPayload = this.setPayload({
      'event': 'view_community',
      'communityid': community.id,
      'community': community.urlId,
      'name': community.city,
      'title': `Nucamp Coding Bootcamp ${community.city}`
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_ids: [community.id],
        content_name: community.city,
        content_type: 'product',
        value: 1,
        currency: 'USD'
      }
    }
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async followBootcampEvent(bootcamp, value, user?) {
    const userCountry = await this.geoData.getsetFullAddressCountryCode(user);
    const contentids = [bootcamp.id, `bootcamp-${bootcamp.bootcamp.id}`];
    if (userCountry) contentids.push(`country-${userCountry}`);
    const clientPayload = this.setPayload({
      'event': 'follow_bootcamp',
      'bootcampid': bootcamp.bootcamp.id,
      'contentids': contentids,
      'label': bootcamp.id,
      'communityid': bootcamp.community.id,
      'community': bootcamp.community.urlId,
      'bootcamptitle': bootcamp.title,
      'tuition': value,
      'items': [{
        item_id: bootcamp.id,
        item_name: bootcamp.title,
        item_category: bootcamp.title,
        item_variant: bootcamp.community.community.name + ", " + bootcamp.community.community.state,
        price: value,
        quantity: 1,
      }],
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_ids: contentids,
        content_name: bootcamp.title,
        content_type: 'product',
        value: 10,
        currency: 'USD'
      },
    }
    this.windowRef.nucampTracking({content: bootcamp.id, campaign: "followbootcamp"});
    await Promise.all([
      this.browseWebsite(user),
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async scheduleAppointmentEvent(){
    const payLoad = this.setPayload({
      'event': 'schedule_appointment'
    });
    await this.pushTagToDOM(payLoad)
  }

  public async viewBootcampEvent(bootcamp, user?) {
    const userCountry = await this.geoData.getsetFullAddressCountryCode(user);
    const contentids = [bootcamp.id, `bootcamp-${bootcamp.bootcamp.id}`];
    if (userCountry) contentids.push(`country-${userCountry}`);

    this.browseWebsite(user);
    const clientPayload = this.setPayload({
      'event': 'view_bootcamp',
      'bootcampid': bootcamp.bootcamp.id,
      'contentids': contentids,
      'label': bootcamp.id,
      'communityid': bootcamp.community.id,
      'community': bootcamp.community.urlId,
      'bootcamptitle': bootcamp.title,
      'items': [{
        item_id: bootcamp.bootcamp.id,
        item_name: bootcamp.title,
        item_category: bootcamp.title,
        item_variant: bootcamp.id,
        price: bootcamp.cost.earlybird,
        quantity: 1,
      }]
    }, user);
    const fbServerPayload = {
      event_name: 'ViewContent',
      event_id: clientPayload.eventID,
      custom_data: {
        content_ids: contentids,
        content_name: bootcamp.title,
        content_type: 'product',
        currency: 'USD'
      },
    }
    this.windowRef.nucampTracking({content: bootcamp.id, campaign: "viewbootcamp"});
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(),
      this.sendServerEvent(fbServerPayload, user).catch()
    ]);
  }

  public async registerBootcampEvent(bootcamp, transactionId, value, user, coupon) {
    const userCountry = await this.geoData.getsetFullAddressCountryCode(user);
    const contentids = [bootcamp.id, `bootcamp-${bootcamp.bootcamp.id}`];
    if (userCountry) contentids.push(`country-${userCountry}`);
    const clientPayload = this.setPayload({
      'event': 'register_' + bootcamp.name.toLowerCase(),
      'bootcampid': bootcamp.bootcamp.id,
      'contentids': contentids,
      'transactionId': transactionId,
      'communityid': bootcamp.community.id,
      'community': bootcamp.community.urlId,
      'bootcamptitle': bootcamp.title,
      'tuition': value,
      'coupon': coupon ? coupon : '',
      'items': [{
        item_id: bootcamp.id,
        item_name: bootcamp.title,
        item_category: bootcamp.title,
        item_variant: bootcamp.community.community.name + ", " + bootcamp.community.community.state,
        price: value,
        coupon: coupon ? coupon : '',
        quantity: 1,
      }],
      'content_id': bootcamp.id,
      'quantity': 1,
      'price': value,
      'value': value,
      'currency': 'USD'
    }, user);

    await this.sendClientEvent(clientPayload, user).catch(() => Promise.resolve());
  }

  public async setUserData(user) {
    const dataPush: any = {};
    if (user.email) dataPush.email = user.email.toLowerCase().trim();
    if (user.phoneId) {
      dataPush.phoneId = user.phoneId;
      dataPush.phone_number = user.phoneId;
    }
    if (user.phone && !user.phoneId) dataPush.phoneId = user.phone;
    if (user.lastname) dataPush.last_name = user.lastname;
    if (user.firstname) dataPush.first_name = user.firstname;
    if (user.location) dataPush.city = user.location;
    if (user.state) dataPush.region = user.state;

    const payload = this.setPayload(dataPush, user);
    await this.pushTagToDOM(payload);
  }

  public async registerBootcampUniversalEvent(bootcamp, transactionId, value, user, coupon) {
    const contentids = [bootcamp.id, `bootcamp-${bootcamp.bootcamp.id}`];
    const clientPayload = this.setPayload({
      'event': 'register_universal',
      'communityid': bootcamp.community.id,
      'community': bootcamp.community.urlId,
      'transactionId': transactionId,
      'transactionTotal': value,
      'value': value,
      'tuition': value,
      'coupon': coupon ? coupon : '',
      'transactionProducts': [{
        'sku': bootcamp.id,
        'name': bootcamp.title,
        'category': bootcamp.community.community.name + ", " + bootcamp.community.community.state,
        'price': value,
        'quantity': 1
      }],
      'items': [{
        item_id: bootcamp.id,
        item_name: bootcamp.title,
        item_category: bootcamp.title,
        item_variant: bootcamp.community.community.name + ", " + bootcamp.community.community.state,
        coupon: coupon ? coupon : '',
        price: value,
        quantity: 1,
      }],
      'content_id': bootcamp.id,
      'quantity': 1,
      'price': value,
      'currency': 'USD'
    }, user);
    const fbServerPayload = {
      event_name: 'Purchase',
      event_id: clientPayload.eventID,
      custom_data: {
        content_ids: contentids,
        content_name: bootcamp.title,
        content_type: 'product',
        value: value,
        currency: 'USD'
      },
      properties: {
        currency: 'USD',
        value: value,
        description: bootcamp.title,
        contents: [{
          content_id: bootcamp.bootcamp.id,
          content_category: "Technology",
          content_name: bootcamp.title,
          price: value,
          content_type: "product",
          quantity: 1,
        }],
      }
    }
    // console.log(clientPayload);
    await Promise.all([
      this.sendClientEvent(clientPayload, user).catch(() => Promise.resolve()),
      this.sendServerEvent(fbServerPayload, user).catch(() => Promise.resolve())
    ]).catch(() => Promise.resolve());
  }
}
