import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { Observable } from 'rxjs';

/*
  Generated class for the ConfigService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class ConfigService {
  config;

  constructor(
    private db: FirestoreService
  ) { }

  public getConfig$(): Observable<any>{
    return this.db.doc$('config/config');
  }

  public getConfig() {
    if (!this.config) {
      // console.log("fetching the config");
      return this.db.afs.firestore.collection('config')
        .doc('config')
        .get()
        .then(doc => {
          this.config = doc.data();
          return this.config;
        });
    }
    return Promise.resolve(this.config);
  }

}
