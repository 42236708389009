import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { map, share, take } from 'rxjs/operators';

/*
  Generated class for the ConfigService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class GeodataService {
  states;
  country;

  constructor(
    private db: FirestoreService
  ) { 
    this.getStates$() 
  }

  public getStates$() {
    if (!this.states) {
      return combineLatest([
        this.db.docWithIds$(`geodata/countries`),
        this.db.docWithIds$(`geodata/states`),
      ]).pipe(
        take(1),
        map(([countries, states]: any) => {
          const USA = states.USA.filter(state => state.code !== "ZZ").map(state => ({code: state.code, name: `USA - ${state.code} - ${state.name}`})).sort((a,b) => a.name < b.name ? -1 : 1);
          const WORLD = countries.countries.map(country => ({code: country.alpha3code, name: country.country.toUpperCase()}));
          this.states = {states: [...USA, ...WORLD], world: countries.countries};
          return this.states;
        }),
        share()
        )
      } else {
        return of(this.states);
      }
  }

  public async getsetFullAddressCountryCode(user) {
    let result;
    if (user && user.fulladdress) {
      const country = user.fulladdress.find(addresscomponent => addresscomponent.types.includes("country"));
      // console.log("country", country);
      if (!this.states) await firstValueFrom(this.getStates$());
      // console.log("this.states", this.states);
      if (country && country.short_name !== "US" && this.states) {
        const internationalcountry = this.states.world.find(c => c.alpha2code === country.short_name);
        // console.log("international", internationalcountry);
        if (internationalcountry) result = internationalcountry.alpha3code;
      } else if (country && country.short_name === "US") {
        // result = "USA";
      }
      if (this.country && result !== this.country || !this.country) {
        this.country = result;
      }
    } else if (this.country) {
      return this.country
    }

    return result;
  }
}
