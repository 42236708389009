import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { FirestoreService } from '../firestore/firestore.service';
import { User, Community, BootcampDelivery, Instructor } from '../../shared/datamodel';
import { WindowrefService } from '../windowref/windowref.service';
import { BehaviorSubject, firstValueFrom, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { convertCase, urlFix } from '../../shared/routines';
import { NucampanalyticsService } from '../nucampanalytics/nucampanalytics.service';
import { ConfigService } from '../config/config.service';
import * as moment from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
moment.extend(utc);
/*
  Generated class for the CommunityService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

@Injectable({providedIn: 'root'})
export class CommunityService {
  public currentCommunity: Community;
  public obsCommunity: BehaviorSubject<Partial<Community>> = new BehaviorSubject(null);
  public regionalCommunities: Community[];

  constructor (
    private db: FirestoreService,
    private location: Location,
    private nucampAnalytics: NucampanalyticsService,
    private config: ConfigService,
    private winRef: WindowrefService
    ) {  }
  

  public updateCommunityNotes(communityid, notes) {
    return this.db.col('community').doc(communityid).ref.update({notes: notes});
  }

  public getAllSearchCommunities(): Observable<Community[]>{
    return this.db.colWithIds$('community_search', ref => ref
      .orderBy('displayName')
      .where('hidden', '==', false))
  }

  public getAllCommunities(): Observable<Community[]>{
    return this.db.colWithIds$('community', ref => ref
      .orderBy('name')
      .where('hidden', '==', false))
  }
  
  public getAllCommunitiesLight(): Observable<Community[]>{
    return this.db.get("/web/community/all");
    // return this.db.colWithIds$('community', ref => ref
    //   .orderBy('name')
    //   .where('hidden', '==', false))
  }

  public selectCommunityPicture(community) {
    const pictures = this.getCommunityPicture(community);
    console.log("getCommunityPicture", community, pictures);
    if (community.type === "online-regional") return pictures.widesm;
    else return pictures.desktop;
  }

  public getCommunityPicture(community) {
    let meta = '';
    if (community.img.meta) meta = community.img.meta

    // const screenWidth = parseInt(this.winRef.width());

    if (community.type === "online-regional") {
      return {
        desktop: community.img.widelg, 
        mobile: community.img.widelg,
        bootcampdetails: community.img.widexl,
        ...community.img,
        meta
      };
    } else {
      const image = {
        desktop: community.img.widelg, 
        mobile: community.img.widesm,
        bootcampdetails: community.img.widexl,
        ...community.img,
        meta
      }
      if (!image.desktop && community.img.banner) image.desktop = image.banner;
      if (!image.mobile && community.img.banner) image.mobile = image.banner;
      return image;
    }
  }

  public getOnlineRegionalCommunities(): Observable<Community[]>{
    return this.db.colWithIds$('community', ref => ref
      .orderBy('geolocation.utcRawOffset')
      .where('type', '==', 'online-regional')
      .where('hidden', '==', false))
  }

  public getWorkshopOptions() {
    return firstValueFrom<any>(this.db.get('/web/workshopoptions'));
  }

  public getAllCommunitiesPromise(): Promise<Community[]>{
    return this.db.col<Community>('community').ref
      .orderBy('name')
      .where('hidden', '==', false)
      .get()
      .then(snap => snap.docs.map(doc => ({id: doc.id, ...doc.data()} as Community)))
  }

  // public getTopCommunitiesByZipCode(latlng, number) {
  //   return this.db.cloudFunctions('getTopCommunitiesByLatLng',{latlng: latlng, number: number}).then(results => results.data);
  // }

  public getTopCommunitiesByZipCode(latlng, number, communityCollection) {
    return firstValueFrom(this.db.post('/web/community/bylatlng',{latlng: latlng, number: number, communityCollection})).then(results => results as any[]);
  }

  public searchCommunitiesByState(state, community, limit) {
      return this.db.colWithIds$<Community>('community_search', ref => ref
        .where('hidden', '==', false)
        .where('state', "==", state)
        .limit(limit + 2))
        .pipe(
          map((communities) => {
            console.log("searchCommunitiesByState");
            return communities.filter((com) => com.displayName !== community.displayName);
          })
        )
  }

  public searchCommunities(communityname) {
    if (communityname && communityname != "") {
      return this.db.colWithIds$('community_search', ref => ref.where('hidden', '==', false)
        .orderBy('displayName')
        .limit(8)
        .startAt(convertCase(communityname))
        .endAt(convertCase(communityname +"\uf8ff"))
      )
    }
    else return of(null);
  }

  public getCommunitybyGeoLocation(number) {
    return this.getLocation().pipe(map(userlocation => {
      const location = {lat: userlocation.coords.latitude, lng: userlocation.coords.longitude};
      return this.getTopCommunitiesByZipCode(location, number, "community_search")
    }))
  }

  getLocation(): Observable<any> {
    return new Observable(obs => {
     navigator.geolocation.getCurrentPosition(
       success => {
         obs.next(success);
         obs.complete();
       },
       error => {
         obs.error(error);
       }
     );
   });
  }

  public getCommunitiesbyCountryState(country: string, state: string): Observable<Community[]>{
    return this.db.colWithIds$('community', ref => ref
      .where('country', '==', country.toUpperCase())
      .where('state', '==', state.toUpperCase())
      .where('hidden', '==', false)
      .orderBy('name')
    );
  }
  
  public getCommunityByNameState(communityname : string, communitystate: string): Observable<Community[]>{
    const urlId = `${communitystate.toLowerCase()}/${urlFix(communityname)}`;
    return this.db.colWithIds$('community', ref => ref
      .where('urlId', '==',  urlId)
      .where('hidden', '==', false)
    ).pipe(
      switchMap((communities: any) => {
        if (communities && communities[0]) {
          return of (communities);
        } else {
          return this.db.colWithIds$('community', ref => ref
            .where('altnames', 'array-contains',  communityname)
            .where('state', '==', communitystate.toUpperCase())
            .where('hidden', '==', false)).pipe(map((communities: any) => {
              if (communities && communities[0]) {
                communities[0].displayName = convertCase(communityname);
                return communities;
              }              
            }))
        }
      })
    );
  }
  
  public getCommunityByNameStatePromise(communityname : string, communitystate: string): Promise<Community>{
    const urlId = `${communitystate.toLowerCase()}/${urlFix(communityname)}`
    return this.db.col<Community>('community').ref
      .where('urlId', '==',  urlId)
      .where('hidden', '==', false)
      .get()
      .then(snapshot => {
        if (!snapshot.empty) {
          const community: any = {id: snapshot.docs[0].id, ...snapshot.docs[0].data()};
          return community;
        } else {
          return this.db.col<Community>('community').ref
            .where('altnames', 'array-contains',  communityname)
            .where('state', '==', communitystate.toUpperCase())
            .where('hidden', '==', false)
            .get()
            .then(snap => {
              if (!snap.empty) {
                const community: any = {id: snap.docs[0].id, ...snap.docs[0].data()};
                community.displayName = convertCase(communityname);
                return community;
              } else {
                return null;
              }
            })
        }
      })
  }

  public getCommunityByGoogleAdsLocation(locationid: number){
    // console.log("LocationId: ", locationid);
    return this.db.colWithIds$<Community>('community', ref => ref
      .where('googleadslocations', 'array-contains',  locationid));
  }

  public getCommunityByAltnameState(communityaltname : string, communitystate: string) {
    return this.db.colWithIds$<Community>('community', ref => ref
      .where('altnames', 'array-contains',  convertCase(communityaltname))
      .where('state', '==', communitystate.toUpperCase())
    );
  }

  public getCommunityJobs(communityid) {
    return this.db.col<any>('communityext').doc(communityid)
      .ref
      .get()
      .then(doc => {
        if (doc.exists) {
          const communityjobs = doc.data();
          communityjobs.groups = communityjobs.groups.slice(0,8);
          communityjobs.salaries = communityjobs.salaries.slice(0,8);
          return communityjobs;
        }
        else return null;
      })
  }

  public getCommunityBySubCommunity(subcommunityId: string) {
    return this.db.colWithIds$<Community>('community', ref => ref
      .where('subcommunity', 'array-contains',  subcommunityId.toUpperCase())
    ).pipe(map(result => {
      if (result && result[0]) return result[0];
      else return null
    }));
  }

  public getCommunityById(communityid){
    return this.db.docWithIds$<Community>('community/' + communityid) as Observable<Community>;
  }

  public getCommunitySearchById(communitysearchid){
    return this.db.docWithIds$<Community>('community_search/' + communitysearchid) as Observable<Community>;
  }

  public getCommunityInstructors(communityid) {
    return this.db.get('/web/community/instructors', {communityid})
  }

  public getCommunityInstructorLead(communityid){
    return this.db.colWithIds$<Instructor>('instructor', ref => ref
      .where('community.communityid', '==', communityid)
      .where('community.instructorlead', '==', true))
  }

  public getCommunityBootcampDeliveries(communityid, allactive?: boolean) {
    if (!allactive) {
      return this.db.colWithIds$<BootcampDelivery>('bootcampdelivery', ref => ref
      .where('community.id', '==', communityid)
      .where('startdate', ">=", moment().subtract(1, 'day').toDate())
      .orderBy('startdate'))
    }
    else {
      return this.db.colWithIds$<BootcampDelivery>('bootcampdelivery', ref => ref
      .where('community.id' , '==', communityid)
      .where('enddate', ">", moment().subtract(60, 'day').toDate())
      .orderBy('enddate'))
    }
  }

  public getCommunityMeetUs(communityid, iDaysBack?){
    let daysback = 0;
    if (iDaysBack) daysback = iDaysBack;
    
    return this.db.colWithIds$<any>('opendoor', ref => ref
    .where("communityid", '==', communityid)
    .where('start' ,'>=', moment().subtract(daysback, 'day').utc().toDate())
    .orderBy('start', 'asc')
    .limit(6))
  }

  public getCommunityMembers(name, state) {
    return this.db.colWithIds$<User>('users', ref => ref
    .where('location', '==', convertCase(name))
    .where('state', '==', state.toUpperCase())
    .orderBy('updatedAt', 'desc')
    .limit(60))
  }

  public getCommunityMembersCloudFunction(community){
    // console.log("Fetch Community Members", community.urlId);
    return this.db.get<any>('/web/community/members', {name: community.name, state: community.state.toUpperCase(), id: community.id, urlId: community.urlId, maxperpage: 30, maxmembers : 90});
  }

  // public getCommunityMembersCloudFunction(name, state){
  //   return this.db.cloudFunctions('getCommunityMembers',{name: name, state: state.toUpperCase(), maxperpage: 24, maxmembers : 96});
  // }

  private toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  public toShow(text) {
    return this.toTitleCase(text.replace(/-/gi, " "));
  }

  public async avoidTXPA(community) {
    let emitCom = community;
    if ((community.state === "TX" || community.state === "PA") && !(this.winRef.isNucampSSRBrowser() || this.winRef.isHeadlessBrowser())) {
      if (!this.regionalCommunities) {
        await firstValueFrom(this.getOnlineRegionalCommunities()).then((communities) => {
          this.regionalCommunities = communities;
        })
      }
      if (community.state === "TX") {
        emitCom = this.regionalCommunities.find(com => com.id === "ONCE");
        this.location.replaceState('community/' + emitCom.state.toLowerCase() + '/' + emitCom.name.toLowerCase());
      } else if (community.state === "PA") {
        emitCom = this.regionalCommunities.find(com => com.id === "ONEA");
        this.location.replaceState('community/' + emitCom.state.toLowerCase() + '/' + emitCom.name.toLowerCase());
      }
    }
    return Promise.resolve(emitCom);
  }

  async emitNextCommunity(nextCommunity) {
    let emitCom = nextCommunity;
    if (!this.currentCommunity || (this.currentCommunity && nextCommunity && this.currentCommunity.id !== nextCommunity.id)) {
      emitCom = await this.avoidTXPA(nextCommunity);
      this.obsCommunity.next(emitCom);
      this.currentCommunity = emitCom;
    }
    // console.log("NOT Emit Next Communtiy", this.currentCommunity);
  }

  public async setCommunity(name, state, community?) {
    const myObj = { name: urlFix(name) , state: state.toLowerCase()};
    let newCommunity: Community;
    // console.log("community.id", community.id);
    // console.log("this.currentCommunity.id", this.currentCommunity.id);

    if (this.currentCommunity && urlFix(this.currentCommunity.name) === myObj.name &&  this.currentCommunity.state.toLowerCase() === myObj.state) {
      newCommunity = this.currentCommunity;
    } else {
      if (!community) newCommunity = await this.getCommunityByNameStatePromise(name, state);
      else newCommunity = community;
    }
    if ((newCommunity && this.currentCommunity && newCommunity.id !== this.currentCommunity.id) 
      || (newCommunity && !this.currentCommunity)) {
      this.winRef.setCommunity(newCommunity.name, newCommunity.state);
      this.nucampAnalytics.viewCommunity(newCommunity);
      // this.currentCommunity = newCommunity;
    }
    // console.log("newCommunity.id", newCommunity.id);
    this.emitNextCommunity(newCommunity);
    return Promise.resolve(newCommunity)
  }

  public setCommunityObs(name, state, community?) {
    return from(this.setCommunity(name, state, community));
  }

  public readandsetCommunity() {
    // console.log("in here1");
    //Try to read from the Google code in the URL
    const communityGoogleAds = parseInt(this.winRef.getCommunityGoogleUrl()) || 0;
    const communityJSONUrl = this.winRef.getCommunityJSONUrl();

    // if the community is passed in the URL then it trumps everything else- at least temporarily
    if (communityJSONUrl.bdid !== "") {
      // console.log("in here2");
      return firstValueFrom(this.getCommunityfromCohort(communityJSONUrl.bdid).pipe(take(1))).then(com => {
        if (com && com.name && com.state) {
          // this.currentCommunity = com;
          this.winRef.setCommunity(com.name, com.state);
          this.nucampAnalytics.viewCommunity(com);
          this.emitNextCommunity(com);
        }
      });
    } else if (communityJSONUrl.name !=="" && communityJSONUrl.state !=="") {
      // console.log("in here3");
      return firstValueFrom(this.getCommunityByNameState(communityJSONUrl.name, communityJSONUrl.state).pipe(take(1))).then(com => {
        if (com[0] && com[0].name && com[0].state) {
          // console.log("This is com", com);
          // this.currentCommunity = com[0];
          this.winRef.setCommunity(com[0].name, com[0].state);
          this.nucampAnalytics.viewCommunity(com[0]);
          this.emitNextCommunity(com[0]);
          // console.log("This is this.currentCommunity", com[0]);
        }
      })
    } else if (communityGoogleAds > 0) {
      // console.log("in here4");
      return firstValueFrom(this.getCommunityByGoogleAdsLocation(communityGoogleAds).pipe(take(1))).then(com => {
        if (com[0] && com[0].name && com[0].state) {
          this.winRef.setCommunity(com[0].name, com[0].state);
          // this.currentCommunity = com[0];
          this.winRef.setCommunity(com[0].name, com[0].state);
          this.nucampAnalytics.viewCommunity(com[0]);
          this.emitNextCommunity(com[0]);
        }
        else this.currentCommunity = null;
      })
    } else {
      // console.log("in here5");
      return this.getCurrentCommunity().then(com => {
        // console.log("in here6", com);
        if (com) {
          // this.currentCommunity = com;
          this.winRef.setCommunity(com.name, com.state);
          this.emitNextCommunity(com);
        }
      })
    }
  }

  public getCommunityfromCohort(cohortId) {
    return this.getCommunityById(cohortId.substring(0,4)).pipe(
      switchMap(com => {
        if (com && com.name && com.state) {
          com.displayName = com.name;
          return of(com);
        }
        else return this.getCommunityBySubCommunity(cohortId.substring(0,4))
      }),
      map(com => {
        if (com) {
          this.winRef.setCommunity(com.name, com.state);
          return com;
        }
        else return null;
      }));
  }

  public getCurrentCommunity(){
    // console.log("this.activatedRoute.snapshot.paramMap", this.activatedRoute.snapshot.paramMap.get('name'), this.activatedRoute.snapshot.paramMap.get('state'));
    // console.log("this.windowRef location", this.winRef.nativeLocation);
    const locationFragments = this.winRef.nativeLocation.pathname.split('/').filter((item) => item);
    // console.log("locationFragments", locationFragments);

    let community

    if (this.currentCommunity) return Promise.resolve(this.currentCommunity);

    // First read it from the community route
    if (!community || (community && !community.name && !community.state)) {
      if (locationFragments && locationFragments.length > 1 && locationFragments[0] === "community") {
        const communitystate= locationFragments[1].toLowerCase();
        const communityname= urlFix(locationFragments[2].toLowerCase());
        community = {name: communityname, state: communitystate};
        // console.log("in here locationFragments", community);
      }
    }
    // Then trying to read it from the URL parameters
    if (!community || (community && !community.name && !community.state) || (community && community.name === "you" && community.state === "near")) {
      // console.log("in here this.winRef.getCommunityJSONUrl()", community);
      community = this.winRef.getCommunityJSONUrl();
    }
    // Then trying to read it from the local storage
    if (!community || (community && !community.name && !community.state) || (community && community.name === "you" && community.state === "near")) {
      // console.log("in here this.winRef.getCommunity()", community);
      community = this.winRef.getCommunity()
    }
    
    if (community && community.name && community.state) {
      // console.log("in here getCommunityByNameStatePromise", community);
      return this.getCommunityByNameStatePromise(community.name, community.state)
        .then((com) => {
          if (!com) {
            return this.nucampAnalytics.getGeoLocation()
            .then((geoData) => {
              if (geoData?.location) {
                return this.getTopCommunitiesByZipCode(geoData.location, 5, "community_search").then(com => {
                  // console.log("This is com", com);
                  if (com && com.length > 0) {
                    return com[0];
                  }
                  else return null
                });
              } else {
                return null;
              }
            });
          } else {
            return com;
          }
      })
    } else {
      return this.nucampAnalytics.getGeoLocation()
        .then((geoData) => {
          if (geoData?.location) {
            return this.getTopCommunitiesByZipCode(geoData.location, 5, "community_search").then(com => {
              // console.log("This is com", com);
              if (com && com.length > 0) {
                return com[0];
              }
              else return null
            });
          } else {
            return null;
          }
        });
    }
  }

  private WLOrderBy(a,b) {
    let comparison = 0;

    if (a.order > b.order) {
      comparison = 1;
    } else if (a.order < b.order) {
      comparison = -1;
    }
  
    return comparison;
  }

  public getCommunityWorkshopLocations(community) {
    if (community.workshops && community.workshops.length > 0) {
      return of(community.workshops);
    } else {
      return this.db.doc$<Community>(`community/${community.id}`).pipe(
        switchMap(result => {
          if (result && result.workshops && result.workshops.length > 0) {
            return of(result.workshops);
          } else {
            return this.db.doc$(`workshoplocation/${community.id}`).pipe(map(result => {
              if (result)
                return Object.keys(result).map(key => result[key]).filter(wl => !wl.hide).sort(this.WLOrderBy);
              else return null;
            }))
          }
        }))
    }
  }

  public getAllWorkshopLocations() {
    return this.db.col$('workshoplocation').pipe(map(communities => {
      return communities.map(result => {
        return Object.keys(result).map(key => result[key]).sort(this.WLOrderBy);
      })
    }))
  }

  public cannotSeeCompleteSE (community) {
    if (community) {
      return this.config.getConfig().then(config => {
        const blackList = config?.completeSE?.blackListCommunities ? config.completeSE.blackListCommunities : [];
        return blackList.some(bk => community.urlId.includes(bk))
      })
    } else {
      return Promise.resolve(false);
    }
  }
}
