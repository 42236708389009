<!--
  Generated template for the BrowseCommunitiesPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header mode="md" class="nucamp-toolbar">
  <ion-toolbar mode="md">
    <ion-buttons slot="end" class="ion-align-self-start">
      <ion-button mode="md" (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <h1 class="ion-text-wrap">Select a Nucamp Community</h1>
    </ion-title>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <browsecommunities #browsecommunities [selectonly]="selectonly" [allcommunities]="allcommunities" numbercommunities="4" [geolocation]="geolocation" [postalcode]="postalcode" (selCommunityEvent)="openCommunity($event)"></browsecommunities>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
