import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { retry, map } from 'rxjs/operators';
import { FirestoreService } from '../firestore/firestore.service';
import { firstValueFrom } from 'rxjs';

/*
  Generated class for the GoogleapiService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class GoogleapiService {
  
  constructor(
    private http: HttpClient,
    private db: FirestoreService,
    ) {
  }

  public zipcodeGeoCode(zipcode, country) {
    return this.http.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|country:${country}&key=${environment.gcpMapsAPI}`,
    ).pipe(map(result => {
      // console.log(result);
      if (result.results.length > 0)
        return result.results[0].geometry.location;
      else return null;
    }))
  }

  public addressSearch(address) {
    return firstValueFrom(this.db.get<any[]>('/web/google/validateaddress',{address: address}, true))
      .then(result => {
        // console.log(result);
        if (result && result.length > 0) {
          const primaryAddress = result[0];
          if (primaryAddress.types && primaryAddress.types.length > 0 && primaryAddress.types.findIndex(term => term === "premise" || term === "street_address") != -1)
            return primaryAddress;
          else return null;
        } else return null;
      })
  }

  public addressGeoCode(address) {
    return this.http.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${environment.gcpAPI}`,
    ).pipe(map(result => {
      // console.log("Address", address);
      // console.log("Result", result);
      if (result.results.length > 0)
        return result.results[0].geometry.location;
      else return null;
    }))
  }

  public validateRECaptcha(response, remoteip) {
    return this.db.post("/web/recaptcha/verify", {response, remoteip})
    .pipe(
      retry(2)
    ) 
  }
}
