import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { filter, startWith, tap } from 'rxjs/operators';
import { NucampanalyticsService } from '../nucampanalytics/nucampanalytics.service';


/*
  Generated class for the NavigateService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class NavigateService {

  constructor(
    private nav: NavController,
    private router: Router,
    private nucampAnalytics: NucampanalyticsService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    // private actionCtrl: ActionSheetController
    ) {
  }

  // enterAnimation = (baseEl: any) => {
  //   const backdropAnimation = this.animationCtrl.create()
  //     .addElement(baseEl.querySelector('ion-backdrop')!)
  //     .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  //   const wrapperAnimation = this.animationCtrl.create()
  //     .addElement(baseEl.querySelector('.modal-wrapper')!)
  //     .keyframes([
  //       { offset: 0, opacity: '0', transform: 'scale(0)' },
  //       { offset: 1, opacity: '0.99', transform: 'scale(1)' }
  //     ]);

  //   return this.animationCtrl.create()
  //     .addElement(baseEl)
  //     .easing('ease-out')
  //     .duration(150)
  //     .addAnimation([backdropAnimation, wrapperAnimation]);
  // }

  // leaveAnimation = (baseEl: any) => {
  //   return this.enterAnimation(baseEl).direction('reverse');
  // }

  transformv3tov5Options (options) {
    const returnOptions: any = {...options};
    if (options && options.animate) returnOptions.animated = false;
    if (options && options.direction) returnOptions.animatedDirection = 'back';
    if (!options || (options && !options.cssClass)) returnOptions.cssClass = 'default-modal';
    return returnOptions;
  }

  navigationStart () {
    return this.router.events.pipe(
      filter( event => event instanceof NavigationStart),
      startWith(null as Event), // Start with something, because the app doesn't fire this on appload, only on subsequent route changes
      tap(event => {
        console.log("Navigation Start");
      }),
    );
  }

  async openModal(page, params, options) {
    // console.log('Modal Params', params);
    let event = "Open Modal";
    if (options && options.event) event = options.event;
    await this.nucampAnalytics.event("Page", "View", event);
    return this.modalCtrl.create({
      component: page, 
      componentProps:params, 
      // enterAnimation: this.enterAnimation, 
      // leaveAnimation:this.leaveAnimation, 
      ...this.transformv3tov5Options(options),
      keepContentsMounted: true
    }).catch(err => {
      console.log('Error opening modal', err);
      return Promise.resolve(err);
    });
  }

  openPage(page, event?:string, options?: NavigationOptions) {
    return this.nav.navigateRoot(page, {...options, animated: true, animationDirection: 'back' })
      .then(() => {
        if (event) {
          this.nucampAnalytics.event("Page", "View", event);
        } else {
          this.nucampAnalytics.event("Page", "View", page);
        }
      })
  }

  openAlert(options, cssClass?) {
    if (!cssClass) return this.alertCtrl.create({...options, cssClass: 'nucamp-alert'});
    else if (cssClass) return this.alertCtrl.create({...options, cssClass: ['nucamp-alert', cssClass]})
  }

  // openActionSheet(options, cssClass?) {
  //   if (!cssClass) return this.actionCtrl.create({...options});
  //   else if (cssClass) return this.actionCtrl.create({...options, cssClass: cssClass})
  // }
}
