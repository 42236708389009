import { Injectable } from '@angular/core';
import { WindowrefService } from './windowref.service';

@Injectable({providedIn: 'root'})
export class ScriptService {
    doc;
    head;
    s = 'string';
    f = false;
    push = 'push';
    readyState = 'readyState';
    onreadystatechange = 'onreadystatechange';
    scripts = {};
    urlArgs;

    constructor (
        private windowRef: WindowrefService,
    ) { 
        this.doc = this.windowRef.nativeDocument;
        this.head = this.windowRef.nativeDocument.getElementsByTagName('head')[0];
    }

    public get(url, fnc) {
        this.create(url, fnc);
    }

    public create(path, fn) {
        if (!this.scripts[path]) {
            if (this.windowRef.isBrowserRendering() && this.windowRef.nativeDocument) {
                const doc:any = this.windowRef.nativeDocument;
                const el = doc.createElement('script');
                let loaded;
                el.onload = el.onerror = el[this.onreadystatechange] = function () {
                  if ((el[this.readyState] && !(/^c|loade/.test(el[this.readyState]))) || loaded) return;
                  el.onload = el[this.onreadystatechange] = null;
                  loaded = 1;
                  fn();
                }
                el.async = 1
                el.src = this.urlArgs ? path + (path.indexOf('?') === -1 ? '?' : '&') + this.urlArgs : path;
                this.head.insertBefore(el, this.head.lastChild);
                this.scripts[path] = true;
            }
        }
      }
}