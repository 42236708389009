<div id="searchbox" #searchbox>
    <ng-container *ngIf="!communitypage && !hideSelector">
        <ion-row *ngIf="winRef.width() <= 500">
          <ion-col size="12" class="ion-text-center nucamp-centered-flex ion-padding">
            <ion-button (click)="getCommunityByGeo()" data-cy="findcommunity" color="nucamp-intermediate">
              <fa-icon [icon]="faMapMarkerAlt" style="padding-right: 8px;"></fa-icon> USE MY LOCATION
            </ion-button>
          </ion-col>
          <ion-col size="12" class="nucamp-centered-flex ion-padding-top">
            <div class="ordivider"></div>
            <div class="ortext1">OR Lookup your community</div>
          </ion-col>
          <ion-col size="12" class="ion-padding-top nucamp-forms nucamp-forms-grey nucamp-centered-flex">
            <!-- <ion-item mode="md" lines="none" mode="md" class="ion-no-padding" style="width: 150px;">
              <ion-input 
              tappable (keyup)="zipCodeSearch($event)" type="text" placeholder='Your zipcode'></ion-input>
            </ion-item> -->
            <!-- <p class="ortext2 ion-padding-horizontal">or</p> -->
            <ion-item mode="md" lines="none" mode="md" style="padding-bottom: 12px;">
              <ion-label position="stacked" style="font-size: 18px; padding-bottom: 8px; font-weight: 500;">Type your city or country name:</ion-label>
              <ion-input id="searchboxcommunity" #searchboxcommunity
                [clearInput]="true" autofocus="true" tappable (keyup)="citySearch($event)" type="text" placeholder='Type here...'></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="winRef.width() > 500" class="ion-padding">
          <ion-col size="5" class="ion-text-center nucamp-centered-flex nucamp-button-large">
            <ion-button (click)="getCommunityByGeo()" data-cy="findcommunity" size="large" color="nucamp-intermediate">
              <fa-icon [icon]="faMapMarkerAlt" style="padding-right: 8px;"></fa-icon> Use my location
            </ion-button>
          </ion-col>
          <ion-col size="2" class="nucamp-centered-flex">
            <div class="ordivider"></div>
            <div class="ortext1">OR</div>
          </ion-col>
          <ion-col size="5" class="nucamp-forms nucamp-forms-grey nucamp-centered-flex">
              <ion-item mode="md" lines="none" mode="md">
                <ion-label position="stacked" style="font-size: 18px; padding-bottom: 8px; font-weight: 500;">Type your city or country name:</ion-label>
                <ion-input id="searchboxcommunity" #searchboxcommunity
                autofocus="true" [clearOnEdit]="true" tappable (keyup)="citySearch($event)" type="text" placeholder='Type here...'></ion-input>
              </ion-item>
          </ion-col>
        </ion-row>
    </ng-container>
</div>
<div id="comlist" #comlist class="community-cards">
  <ng-container *ngIf="!communitypage">
    <ion-row *ngIf="loading" class="ion-padding-bottom">
      <ion-col size="12" class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="communities">
      <ng-container *ngTemplateOutlet="regionalCommunities(communities) ? imagetemplate : cardtemplate; context:{communities: communities}"></ng-container>
    </ion-row>
    <ion-row *ngIf="allcommunities">
      <ng-container *ngTemplateOutlet="allcommunitiestemplate; context:{communities: communities}"></ng-container>
    </ion-row>
    <ion-row *ngIf="onlineCommunities">
      <ng-container *ngTemplateOutlet="regionalCommunities(onlineCommunities) ? imagetemplate : cardtemplate; context:{communities: onlineCommunities}"></ng-container>
    </ion-row>
  </ng-container>
  <ng-container *ngIf="communitypage">
    <ion-row *ngIf="communities">
      <ng-container *ngTemplateOutlet="regionalCommunities(communities) ? imagetemplate : cardtemplate; context:{communities: communities}"></ng-container>
    </ion-row>
  </ng-container>
</div>
<ng-template #cardtemplate let-communities="communities">
  <ion-col *ngFor="let community of communities" size="12" size-lg="6" class="ion-no-padding" (click)="selectCommunity(community)">
    <ion-card>
      <div class="href communityimage">
        <img loading="lazy" class="href" [src]="community.communityPicture" alt="Nucamp Coding Bootcamp in {{community.displayName}}, {{community.statelong}}"/>
        <div class="communityoverlay">{{community.displayName}}<ng-container *ngIf="!community.online && !community.international">, {{community.state}}</ng-container><ng-container *ngIf="community.international && community.showaltname">, {{community.statelong}}</ng-container>
        </div>
        <div class="image-attribution" *ngIf="community && community.img && community.img.meta" [innerHTML]="community.img.meta"></div>
      </div>
      <span style="display:none"><a href="/community/{{community.urlId}}">Nucamp Coding Bootcamp in {{community.displayName}}<ng-container *ngIf="!community.online">, {{community.statelong}}</ng-container></a></span>
    </ion-card>
  </ion-col>
</ng-template>
<ng-template #allcommunitiestemplate let-communities="communities">
  <ion-col *ngFor="let community of communities" size="12" size-lg="6" class="ion-no-padding" (click)="selectCommunity(community)">
    <ion-card>
      <div class="href communityimage">
        <a href="/community/{{community.urlId}}"><img loading="lazy" class="href" [src]="community.communityPicture" alt="Nucamp Coding Bootcamp in {{community.displayName}}, {{community.statelong}}"/></a>
        <div class="communityoverlay">{{community.displayName}}<ng-container *ngIf="!community.online && !community.international">, {{community.state}}</ng-container><ng-container *ngIf="community.international && community.showaltname">, {{community.statelong}}</ng-container>
        </div>
        <div class="image-attribution" *ngIf="community && community.img && community.img.meta" [innerHTML]="community.img.meta"></div>
      </div>
      <a href="/community/{{community.urlId}}">Nucamp Coding Bootcamp in {{community.displayName}}<ng-container *ngIf="!community.online">, {{community.statelong}}</ng-container></a>
    </ion-card>
  </ion-col>
</ng-template>
<ng-template #imagetemplate let-communities="communities">
  <ng-container *ngIf="!selectonly">
    <ion-col size="12" class="nucamp-centered-flex ion-padding-vertical" (click)="toggleShowWorkshopOptions()">
      <div class="ordivider ion-margin-vertical"></div>
      <div class="ortext1 hover">
        <ion-icon [ngClass]="{'ion-hide': showWorkShopOptions}" name="caret-down-outline" color="primary" style="padding-right: 4px"></ion-icon>
        <ion-icon [ngClass]="{'ion-hide': !showWorkShopOptions}" name="caret-up-outline" color="primary" style="padding-right: 4px"></ion-icon>
        Or pick a convenient time
        <ion-icon [ngClass]="{'ion-hide': showWorkShopOptions}" name="caret-down-outline" color="primary" style="padding-left: 4px"></ion-icon>
        <ion-icon [ngClass]="{'ion-hide': !showWorkShopOptions}" name="caret-up-outline" color="primary" style="padding-left: 4px"></ion-icon>
      </div>
    </ion-col>
    <ion-col size="12" class="ion-text-center hover href ion-padding-bottom" [ngClass]="{'ion-hide': showWorkShopOptions}" (click)="toggleShowWorkshopOptions()">
        <span class="borderbottom" style="font-size: smaller;">show the workshop day & time options</span>
    </ion-col>
    <ion-col size="12" class="ion-text-center ion-padding-horizontal" [ngClass]="{'ion-hide': !showWorkShopOptions}">
      <workshopoptions [browsecommunities]="true" (selectCommunity)="selectCommunity($event)"></workshopoptions>
    </ion-col>
  </ng-container>
  <ion-col size="12" *ngIf="winRef.width() <= 500" style="height: 400px;"></ion-col>
</ng-template>