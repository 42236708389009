import {Injectable} from '@angular/core';
import {FirestoreService} from '../firestore/firestore.service';
import { ToastController } from '@ionic/angular';
import * as moment from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { userRouter } from 'functions/src/app/users/users';
import { WindowrefService } from '../windowref/windowref.service';
moment.extend(isBetween);

/*
  Generated class for the FeaturedvideosService provider.
*/
@Injectable({providedIn: 'root'})
export class NotificationsService {
  notificationShown = false;
  notifications:any;

  constructor(
    private db: FirestoreService,
    private toast: ToastController,
    private winRef: WindowrefService,
    private router: Router
  ) {
    this.getNotifications();
  }

  getNotifications() {
    return this.db.doc<any>('config/notifications').ref.get().then(doc => {
      this.notifications = doc.data();
    });
  }

  isAmericasButUSandCAN(url, usr, customertz, geoData) {
    // console.log(url, usr, customertz, geoData);
    if (usr?.country === "US" || usr?.country === "CAN") {
      return false;
    }
    const AmericasButUSandCanada3L = [
        "MEX", "BLZ", "CRI", "SLV", "GTM", "HND", "NIC", "PAN", // North America
        "ARG", "BOL", "BRA", "CHL", "COL", "ECU", "GUY", "PRY", "PER", "SUR", "URY", "VEN" // South America
    ].map((country) => country.toLowerCase());
    const AmericasButUSandCanada2L = [
      "MX", "BZ", "CR", "SV", "GT", "HN", "NI", "PA", // North America
      "AR", "BO", "BR", "CL", "CO", "EC", "GY", "PY", "PE", "SR", "UY", "VE" // South America
    ].map((country) => country.toLowerCase());
    
    let isPartOfAmericasButUSandCAN = false;
    if (url && AmericasButUSandCanada3L.some(country => url.includes(`/community/${country}/`))) {
      isPartOfAmericasButUSandCAN = true;
    } else if (geoData && geoData.country) {
      if (AmericasButUSandCanada3L.includes(geoData.country.toLowerCase()) || AmericasButUSandCanada2L.includes(geoData.country.toLowerCase())) {
        isPartOfAmericasButUSandCAN = true;
      }
    } else if (customertz < -10 || customertz > -4) {
      isPartOfAmericasButUSandCAN = true;
    }
    return isPartOfAmericasButUSandCAN;
  }

  public async showNotifications(usr) {
    const url = this.winRef.nativeWindow.location.pathname;
    const geoData = this.winRef.getGeoData(usr);
    if (url && url.includes("/pa") || geoData.region === "PA") {
      this.notificationShown = true;
      return Promise.resolve();
    } else {
      if (!this.notificationShown) {
        this.notificationShown = true;
        const customertz = moment().utcOffset() / 60;
        return firstValueFrom(this.db.get('/web/notifications', {userUID: usr?.uid ? usr.uid : null}))
          .then((toastOptions:any) => {
            if (toastOptions && toastOptions.buttons && toastOptions.buttons.find(button => button.fragment)) {
              const navButton = toastOptions.buttons.find(button => button.fragment);
              navButton["handler"] = () => {
                this.router.navigate([navButton.fragment], { replaceUrl: true });
              }
              return this.toast.create(toastOptions).then(t => t.present());
            }
            // else if (this.isAmericasButUSandCAN(url, usr, customertz, geoData) && (!usr || (usr && !usr.subscriptions))) {
            //   return this.toast
            //     .create({header: "ANNOUNCING NEW $99/m PAYMENT PLAN", message: "We are thrilled to announce a new $99/m payment plan for all bootcamps!", color: "nucamp-backend", position: "middle", duration: 20000, buttons: [{text:'Ok', role:'cancel'}, {text:'Learn More', handler: () => {this.router.navigate(["/blog/announcing-nucamp-flex"], { replaceUrl: true })}}]})
            //     .then(t => t.present());
            // }
          })
      }  
    }
  }
}
