import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
// import ls from '@livesession/sdk'
// import LogRocket from 'logrocket';
import { ConfigService } from '../config/config.service';
import { ScriptService } from '../windowref/scriptjs.service';
import { WindowrefService } from '../windowref/windowref.service';
// import LogRocket from 'logrocket';

let LogRocket;

@Injectable({ providedIn: 'root' })
export class SessionrecordingService {
  recordingConfig;
  lsSwitched = false;
  logrocketSwitched = false; 

  constructor(
    private config: ConfigService,
    private windowRef: WindowrefService,
    private scriptjs: ScriptService
  ) {   }

  async fetchConfig() {
    if (!this.recordingConfig) {
      this.recordingConfig = await this.config.getConfig().then(config => config.webconfig.screenrecording);
    }
  }
  
  init() {
    return this.fetchConfig().then(() => {
      try {
        if (environment.production && this.recordingConfig.livesession.active && !this.lsSwitched && this.windowRef.nativeLocation.host === "www.nucamp.co") {
          // ls.init(this.recordingConfig.livesession.id);
          this.lsSwitched = true;
        }
        if (environment.production && this.recordingConfig.logrocket.active && !this.logrocketSwitched && this.windowRef.nativeLocation.host === "www.nucamp.co") {
          if (this.windowRef.isBrowserRendering() && !this.windowRef.nativeWindow.parent?.LogRocket) {
            this.scriptjs.get("https://cdn.lr-in-prod.com/LogRocket.min.js", () => {
              LogRocket = this.windowRef.nativeWindow.parent.LogRocket;
              LogRocket.init(this.recordingConfig.logrocket.id);
              this.logrocketSwitched = true;
             })
          }
        }
      } catch {}
    })
  }

  startRecording() {
    try {
      // console.log("Screen recording starting");
      return this.init()
    } catch {}
  }

  indentifyUser (userUID, name, email) {
    this.init().then(() => {if (this.logrocketSwitched) {
        LogRocket.identify(userUID, {name, email})
      }
    })
  }

  randomRecording() {
    this.fetchConfig().then(() => {
      // console.log("Evaluating starting random screen recording");
      const max = this.recordingConfig.sampling;
      const roll = Math.floor(Math.random() * Math.floor(max));
      if (roll === max - 2) { // arbitray number under the max
        // console.log("Screen recording starting");
        this.startRecording();
      } else {
        // console.log("Screen recording NOT starting");
      }
    })
  }
}
