import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';

import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private afAuth: AngularFireAuth,
        ) {}

    getUserIdToken(): Observable<string> {
        return from (this.afAuth.currentUser.then(user => {
            if (user) {
            return user.getIdToken()
            } else {
            return null;
            }}))
        }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let isCSV = false;
        if (request.url.indexOf('oauthCallback') > -1 || request.url.indexOf('ipify.org') > -1 || request.url.indexOf('https://mautic') > -1 || request.url.indexOf('googleapis') > -1) { 
            return next.handle(request);
        }
        if (request.url.toLowerCase().indexOf("csvformat") > -1) {
            isCSV = true;
        }

        return this.afAuth.idToken.pipe(
            switchMap(token => {
                if (token) {
                    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
                    if (isCSV) {
                        headers.append('Accept', 'text/csv');
                        request = request.clone({ headers, responseType: 'text' });
                    } else {
                        request = request.clone({ headers });
                    }
                }
                return next.handle(request)
                .pipe(tap((event: HttpEvent<any>) => event, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                      console.error(err);
                      return err;
                    } else return err;
                  })
                )
            })
        )
    }
}
