import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../shared/datamodel';
import { environment } from '../../../environments/environment'
import { FirestoreService } from '../firestore/firestore.service';
import * as moment from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { firstValueFrom } from 'rxjs';
moment.extend(advancedFormat);
/*
  Generated class for the MauticService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class MauticService {
  
  constructor(
    public http: HttpClient,
    private db: FirestoreService) {
  }

  public formPost(params){
    let formData = new HttpParams();
    // console.log('params :' + params);
    for (const mparam in params) {
      if (mparam) {
        formData = formData.append('mauticform[' + mparam + ']', params[mparam]);
      }
    }
    // console.log('formData = ' + formData)
    //PAUSING POSTING FORMS FOR DEV PURPOSES
    return firstValueFrom(this.http.post(`${environment.mauticURL}/form/submit?`, formData)).catch(err => console.log(err));
  }

  private subs(content, subs, escape) {
    // console.log("content", content);
    // console.log("subs", subs);
    let subdContent = content;
    Object.keys(subs).forEach(key => {
      const regex = new RegExp(`${escape[0]}${key}${escape[1]}`,"g");
      // console.log("regex", regex);
      // console.log("subs.key", subs[key]);
      subdContent = subdContent.replace(regex, subs[key]);
    })
    //console.log("content", subdContent);
    return subdContent;
  }

  public parseEmail(substitutions, template) {
    const body1 = encodeURIComponent(this.subs(template.body, substitutions, ['{', '}']));
    const body2 = this.subs(body1, {crlf: "%0D%0A"}, ['',''])
    return `mailto:${encodeURIComponent(this.subs(template.mailto, substitutions, ['{', '}']))}
      ?subject=${encodeURIComponent(this.subs(template.subject, substitutions, ['{', '}']))}
      &${encodeURIComponent("content-type")}=${encodeURIComponent("text/plain")}
      &cc=${encodeURIComponent(this.subs(template.cc, substitutions, ['{', '}']))}
      &body=${body2}`;
  }

  public sendEmail(message) {
    // console.log("email message", message);
    return firstValueFrom(this.db.post('/mautic/sendemail',message))
  }

  public updateMautic(contact) {
    return firstValueFrom(this.db.post('/mautic/updatecontact', contact))
  }

  public AddEmployeeToBusinessCampaign (user: User, formID: number){
    let usrphone = "";
    const fformId = formID;

    // console.log("fformId",fformId);
    if (user.phone) usrphone = user.phone.toString(); 
    const formData = {
      'your_first_name': user.firstname,
      'your_last_name': user.lastname,
      'your_email': user.email,
      'your_phone': usrphone,
      'your_mobile': usrphone,
      'formId': fformId,
    }
    if (user?.company?.url) formData['companywebsite'] = user.company.url;
    // console.log(formData);
    return this.formPost(formData);
  }

  public AddUserToBootcampFollowStream (user: User, location: string, state: string, nucampFlex, formID: number){
    console.log("AddUserToBootcampFollowStream nucampFlex", nucampFlex);
    let usrphone = "";
    let fformId = formID;
    // Overriding the bootcamp nurture stream if it's not Job Hunting or Cybersecurity. Otherwise all nurture go to the "All Bootcamp" nurture campaign
    if (fformId !== 33 && fformId !== 56) fformId = 52;

    // console.log("fformId",fformId);
    if (user.phone) usrphone = user.phone.toString(); 
    const formData = {
      'your_first_name': user.firstname,
      'your_last_name': user.lastname,
      'your_preferred_location': location,
      'preferred_state': state,
      'your_email': user.email,
      'your_phone': usrphone,
      'your_mobile': usrphone,
      'formId': fformId,
    }
    if (nucampFlex) formData["nucamp_flex"] = nucampFlex;
    if (user.communityUrlId) formData["community_urlid"] = user.communityUrlId;
    // console.log(formData);
    return this.formPost(formData);
  }

  public AddUserToEloquentJavaScriptOffering (user: User, location: string, state: string){
    let usrphone = "";

    // console.log("fformId",fformId);
    if (user.phone) usrphone = user.phone.toString(); 
    const formData = {
      'your_first_name': user.firstname,
      'your_last_name': user.lastname,
      'your_preferred_location': location,
      'preferred_state': state,
      'your_email': user.email,
      'your_phone': usrphone,
      'your_mobile': usrphone,
      'formId': 54,
    }
    if (user.communityUrlId) formData["community_urlid"] = user.communityUrlId;
    // console.log(formData);
    return this.formPost(formData);
  }

  public CaptureUserData (user: User, location, state, nucampFlex){
    if ((user.firstname == undefined && user.lastname == undefined) || (user.firstname == "undefined" || user.lastname == "undefined")) {
      return Promise.resolve();
    }
    const formID = 12;
    const formData = {
      'first_name': user.firstname,
      'last_name': user.lastname,
      'formId': formID,
    }
    if (user.phone) formData["phone"] = user.phone;
    if (user.phone) formData["mobile"] = user.phone;
    if (location) formData["preferred_location"] = location;
    if (nucampFlex) formData["nucamp_flex"] = nucampFlex;
    if (state) formData["preferred_state"] = state;
    if (user.email) formData["email"] = user.email;
    // if (user.country) formData["country"] = user.country;
    if (user.communityUrlId) formData["community_urlid"] = user.communityUrlId;

    // console.log("Capture User Form Data", formData);
    return this.formPost(formData).catch(err => {
      console.log(err);
      return Promise.resolve();
    });
  }

  public eMailNewsletter (email, name){
    const formID = 20;
    let firstname = '';
    let lastname = '';
    if (name) {
      if (name.firstName) firstname = name.firstName;
      if (name.lastName) lastname = name.lastName;
    }
    const formData = {
      'email': email,
      'firstname': firstname,
      'lastname': lastname,
      'formId': formID,
    }
    console.log("formData", formData);
    return this.formPost(formData);
  }

  public ScheduleAppointment (user: User){
    const formID = 11
    const formData = {
      'your_first_name': user.firstname,
      'your_last_name': user.lastname,
      'your_email': user.email,
      'phone': user.phone,
      'mobile': user.phone,
      'location': user.meetus.location,
      'preferred_state': user.state,
      'bootcamp': user.meetus.preferredBootcamp,
      'appointment_date': moment(user.meetus.appointmentDate).format("dddd, MMMM Do YYYY, h:mm a"),
      'appointment_location': user.meetus.appointmentLocation,
      'motivation': user.meetus.motivation,
      'coding_experience': user.meetus.codingxp,
      'found_us': user.meetus.foundus,
      'online': user.meetus.online,
      'formId': formID,
    }
    if (user.meetus.online) formData["appointment_address"] = `${user.meetus.appointmentAddress} at ${user.meetus.url}`;
      else formData["appointment_address"] = user.meetus.appointmentAddress;
    if (user.meetus.url) formData["url"] = user.meetus.url;
      else formData["url"] = "";
    if (user.communityUrlId) formData["community_urlid"] = user.communityUrlId;
    
    return this.formPost(formData);
  }
}
