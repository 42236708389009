import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as weekday from 'dayjs/plugin/weekday';
moment.extend(customParseFormat);
moment.extend(weekday);

export const dayMapping = {"Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5, "Saturday": 6, "Sunday": 0};

export function convertCase(str) {
    const lower = String(str).toLowerCase().replace(/-/gi, " ");
    const upper = lower
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return upper;
}

export function formatDuration(millisecondsTotal) {
  const hours = Math.floor(millisecondsTotal / (3600 * 1000));
  const minutes = Math.floor((millisecondsTotal - (hours * 3600 * 1000)) / (60 * 1000));
  const seconds = Math.floor((millisecondsTotal - (hours * 3600 * 1000) - (minutes * 60 * 1000)) / 1000);
  const milliseconds = Math.round(millisecondsTotal - (hours * 3600 * 1000) - (minutes * 60 * 1000) - (seconds * 1000));

  // Pad the minutes, seconds, and milliseconds with leading zeros, if required
  const hoursDisplay = hours < 10 ? "0" + hours : hours;
  const minutesDisplay = minutes < 10 ? "0" + minutes : minutes;
  const secondsDisplay = seconds < 10 ? "0" + seconds : seconds;
  const millisecondsDisplay = milliseconds.toString().padStart(3, '0');

  return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}.${millisecondsDisplay}`;
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
}

export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isDSTPeriodOfYear(date) {
  console.log("isDSTPeriodOfYear");
  const year = moment(date).year();
  
  // Get second Sunday of March
  const start = moment(`${year}-03-01`).weekday(7);

  // Get first Sunday of November
  const end = moment(`${year}-11-01`).weekday(0);
  if(end.day() !== 0) {
      end.add(1, 'week');
  }

  // Set the DST switch times to 2:00 am.
  start.hour(2);
  end.hour(2);

  // Returns true if date is equal or after start and before end
  return moment(date).isBetween(start, end, null, '[)');
}

export function isDST(dateInput) {
  const fullYear = dateInput.getFullYear()|0;
// "Leap Years are any year that can be exactly divided by 4 (2012, 2016, etc)
 //   except if it can be exactly divided by 100, then it isn't (2100,2200,etc)
 //	  except if it can be exactly divided by 400, then it is (2000, 2400)"
// (https://www.mathsisfun.com/leap-years.html).
  const isLeapYear = ((fullYear & 3) | (fullYear/100 & 3)) === 0 ? 1 : 0;
// (fullYear & 3) = (fullYear % 4), but faster
  //Alternative:var isLeapYear=(new Date(currentYear,1,29,12)).getDate()===29?1:0
  const fullMonth = dateInput.getMonth()|0;
  return (
      // 1. We know what the time since the Epoch really is
      (+dateInput) // same as the dateInput.getTime() method
      // 2. We know what the time since the Epoch at the start of the year is
      - (+new Date(fullYear, 0, 0)) // day defaults to 1 if not explicitly zeroed
      // 3. Now, subtract what we would expect the time to be if daylight savings
      //      did not exist. This yields the time-offset due to daylight savings.
      - ((
          ((
              // Calculate the day of the year in the Gregorian calendar
              // The code below works based upon the facts of signed right shifts
              //    • (x) >> n: shifts n and fills in the n highest bits with 0s 
              //    • (-x) >> n: shifts n and fills in the n highest bits with 1s
              // (This assumes that x is a positive integer)
              (31 & ((-fullMonth) >> 4)) + // January // (-11)>>4 = -1
              ((28 + isLeapYear) & ((1-fullMonth) >> 4)) + // February
              (31 & ((2-fullMonth) >> 4)) + // March
              (30 & ((3-fullMonth) >> 4)) + // April
              (31 & ((4-fullMonth) >> 4)) + // May
              (30 & ((5-fullMonth) >> 4)) + // June
              (31 & ((6-fullMonth) >> 4)) + // July
              (31 & ((7-fullMonth) >> 4)) + // August
              (30 & ((8-fullMonth) >> 4)) + // September
              (31 & ((9-fullMonth) >> 4)) + // October
              (30 & ((10-fullMonth) >> 4)) + // November
              // There are no months past December: the year rolls into the next.
              // Thus, fullMonth is 0-based, so it will never be 12 in Javascript
              
              (dateInput.getDate()|0) // get day of the month
      
          )&0xffff) * 24 * 60 // 24 hours in a day, 60 minutes in an hour
          + (dateInput.getHours()&0xff) * 60 // 60 minutes in an hour
          + (dateInput.getMinutes()&0xff)
      )|0) * 60 * 1000 // 60 seconds in a minute * 1000 milliseconds in a second
      - (dateInput.getSeconds()&0xff) * 1000 // 1000 milliseconds in a second
      - dateInput.getMilliseconds()
  );
}

export function validateSSNARN(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    if (control.value && control.value.length >= 7) {
      const SSNRegEx = new RegExp(/^(?!000)(?!666)(?!9[0-9][0-9])\d{3}[- ]?(?!00)\d{2}[- ]?(?!0000)\d{4}$/);
      const ARNRegEx = new RegExp(/^[Aa]?\d{3}\-?\d{3}\-?\d{3}$/);
      const isSSN = SSNRegEx.test(control.value);
      const isARN = ARNRegEx.test(control.value);
      console.log("control.value", control.value);
      console.log("isSSN", isSSN);
      console.log("isARN", isARN);
      if (isSSN || isARN) return null;
      else return {'notSSNorARN': true};
    } else {
        return {'notSSNorARN': true};
    }
  }
}

export function validateEIN(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    if (control.value && control.value.length >= 9) {
      const EINRegEx = new RegExp(/^(?![01][789]|2[89]|[46]9|7[089]|89|9[67])\d\d-\d{7}$/);
      const isEIN = EINRegEx.test(control.value);
      console.log("control.value", control.value);
      console.log("isEIN", isEIN);
      if (isEIN) return null;
      else return {'notEIN': true};
    } else {
        return {'notEIN': true};
    }
  }
}

export function validateCityStateZipcode(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const cleanedValue = control.value.replace(/,|;/g, " ").split(" ").map(word => word.trim()).filter(word => word);
    if (cleanedValue.length < 3 || !cleanedValue.every(val => val.length >= 2) || !cleanedValue.find(val => !isNaN(val) && val.length >= 5)) {
      return {'notComplete': true}
    } else {
      return null;
    }
  }
}

export function validateSameText(shouldEquate): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    // console.log(control.value);
    const cleanedValue = RemoveAccents(control.value).trim().replace(/\s+/g, '').toLowerCase();
    // console.log(cleanedValue);
    if (cleanedValue !== RemoveAccents(shouldEquate).trim().replace(/\s+/g, '').toLowerCase()) {
      return {'notComplete': true}
    } else {
      return null;
    }
  }
}

export function validateSignature(name): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if (value) {
      const isValidSignature = (RemoveAccents(value).replace(/\s+/g, '').toLowerCase() === RemoveAccents(name).replace(/\s+/g, '').toLowerCase());
      if (!isValidSignature) return {'notValid': true};
      else return null;
    } else {
      return null;
    }
  }
}

export function validateNWords(nWords): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    // console.log(control.value);
    const cleanedValue = control.value.replace(/,|;|\.|-/g, " ").split(" ").map(word => word.trim()).filter(word => word);
    // console.log(cleanedValue);
    if (cleanedValue.length < nWords) {
      return {'notComplete': true}
    } else {
      return null;
    }
  }
}

export function validate10Words(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    // console.log(control.value);
    const cleanedValue = control.value.replace(/,|;|\.|-/g, " ").split(" ").map(word => word.trim()).filter(word => word);
    // console.log(cleanedValue);
    if (cleanedValue.length < 10) {
      return {'notComplete': true}
    } else {
      return null;
    }
  }
}

export function RemoveAccents(strAccentsInput) {
  const strAccents = strAccentsInput.split('');
  const strAccentsOut = [];
  const strAccentsLen = strAccents.length;
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž’';
  const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'";
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) != -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else {
      strAccentsOut[y] = strAccents[y];
    }
  }
  
  return strAccentsOut.join('');
}

export function urlFix(str) {
  return str.toLowerCase().replace(/and /gi, "").replace(/ /gi, "-").replace(/\./gi, "").replace(/[^a-z0-9\-. ]/gi, '');
}

export function generateInstructorCode(displayName?: string, firstName?: string, lastName?: string) {
  let code = "";

  if (displayName) {
    const arrName = displayName
      .toUpperCase()
      .split(" ")
      .filter(name => name !== "");
    code = arrName[0].trim().substring(0, 1) + arrName[1].trim().substring(0, 2);
  } else if (firstName && lastName) {
    code = firstName.toUpperCase()
      .trim()
      .substring(0, 1)
      + lastName.toUpperCase()
      .trim()
      .substring(0, 2);
  }
  return code;
}

export function openJobsWindow(windowRef) {
  const window =  windowRef.nativeWindow;
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;
    const width = window.innerWidth ? window.innerWidth : window.document.documentElement.clientWidth ? window.document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : window.document.documentElement.clientHeight ? window.document.documentElement.clientHeight : window.screen.height;
    const left = ((width / 2) - (1000 / 2)) + dualScreenLeft;
    const top = ((height / 2) - (700 / 2)) + dualScreenTop;
    const newWindow = window.open(`https://mautic.nucamp.co/job-application`, 'title', 'scrollbars=yes, width=' + 1000 + ', height=' + 700 + ', top=' + top + ', left=' + left);
    if (window.focus) {
        newWindow.focus();
    }
}

// Convert Degress to Radians
function Deg2Rad(deg) {
  return deg * Math.PI / 180;
}

export function splitArrayInChunks(arr, n) {
  const res = [];
  while (arr.length) {
    res.push(arr.splice(0, n));
  }
  return res;
}

export function chunkifyFixedSize(arr, size) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
}

export function chunkify(a, n, balanced) {
  if (n < 2)
      return [a];

  const len = a.length;
  const out = [];
  let i = 0;
  let size;

  if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
          out.push(a.slice(i, i += size));
      }
  }

  else if (balanced) {
      while (i < len) {
          size = Math.ceil((len - i) / n--);
          out.push(a.slice(i, i += size));
      }
  }

  else {

      n--;
      size = Math.floor(len / n);
      if (len % size === 0)
          size--;
      while (i < size * n) {
          out.push(a.slice(i, i += size));
      }
      out.push(a.slice(size * n));

  }
  return out;
}

function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
  const llat1 = Deg2Rad(lat1);
  const llat2 = Deg2Rad(lat2);
  const llon1 = Deg2Rad(lon1);
  const llon2 = Deg2Rad(lon2);
  const R = 6371; // km
  const x = (llon2 - llon1) * Math.cos((llat1 + llat2) / 2);
  const y = (llat2 - llat1);
  const  d = Math.sqrt(x * x + y * y) * R;
  return d;
}

function distanceSort(a, b) {
  let comparison = 0;
  const aa = a.dif;
  const bb = b.dif;

  if (aa > bb) {
    comparison = 1;
  } else if (aa < bb) {
    comparison = -1;
  }

  return comparison;
}

export function NearestCity(userLocation, communities) {
  return communities.map(community => {
    community.dif = PythagorasEquirectangular(userLocation.lat, userLocation.lng, community.geolocation.lat, community.geolocation.lng);
    return community
  }).sort(distanceSort).slice(0, 8)
}

export function shuffleArray(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
}
