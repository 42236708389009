import { Injectable } from '@angular/core';
import { Crisp } from "crisp-sdk-web";
import { environment } from 'src/environments/environment';

Crisp.configure(environment.crispChatWebsiteID);

@Injectable({ providedIn: 'root' })
export class ChatService {

  constructor(
  ) {   }

  identifyUser(user) {
    try {
      const data:any = {
        website: "www.nucamp.co"
      };
      if (user) {
        data.UID = user.uid,
        Crisp.user.setEmail(user.email);
        Crisp.user.setNickname(user.displayName);
      }
      // console.log("identifyUser", data);
      Crisp.session.setData(data);
    } catch (error) {
      console.log("Error while seeting Crisp data", user);
    }
  }

  show() {
    Crisp.chat.show();
  }

  hide() {
    Crisp.chat.hide();
  }
}
