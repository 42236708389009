import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CookieModule } from 'ngx-cookie';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { Loader } from '@googlemaps/js-api-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { TokenInterceptor } from './app/http/token.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { IonicRouteStrategy, IonicModule } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

Bugsnag.start({
  apiKey: '53641a54cbc4afbc395bfca6cc1053c1', 
  appVersion: `a10i5`,
  onError: (event) => {
    const chunkFailedMessage = /chunk [\d]+ failed/;
    if ((event.errors.some(err => chunkFailedMessage.test(err.errorMessage)))) {
      window.location.reload();
    }
  }
})
function errorHandlerFactory() {
  if (environment.production) { return new BugsnagErrorHandler(); } 
  else { return new ErrorHandler(); }
}

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.firebase.authDomain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};



if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
          BrowserModule, 
          IonicModule.forRoot(), 
          AppRoutingModule, 
          AngularFireModule.initializeApp(environment.firebase), 
          AngularFirestoreModule, 
          AngularFireAuthModule, 
          CookieModule.withOptions(), 
          NgcCookieConsentModule.forRoot(cookieConfig), 
          GoogleTagManagerModule.forRoot({ id: environment.googleTagManagerId })
        ),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        { provide: Loader,
            useValue: new Loader({
                apiKey: 'AIzaSyDKpR4HFBHP1SDZrrchPKCgc1vmenNC08Q',
                libraries: ['places']
            })
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
.catch(err => console.error(err));
}


if (document.readyState === 'complete') {
 bootstrap();
} else {
 document.addEventListener('DOMContentLoaded', bootstrap);
}
