export const environment = {
    production: true,
    firebase : {
      apiKey: "AIzaSyDkEviKyPlRFi_VHHL0Oq0Okm0lj4kLNqE",
      authDomain: "nucamp-production.firebaseapp.com",
      databaseURL: "https://nucamp-production.firebaseio.com",
      projectId: "nucamp-production",
      storageBucket: "nucamp-production.appspot.com",
      messagingSenderId: "309135952452"
    },
    gcpAPI: "AIzaSyDkEviKyPlRFi_VHHL0Oq0Okm0lj4kLNqE",
    gcpMapsAPI: "AIzaSyDKpR4HFBHP1SDZrrchPKCgc1vmenNC08Q",
    functionsURL: "https://us-central1-nucamp-production.cloudfunctions.net",
    stripePublishable: "pk_live_wN0uz0NJh8LJvuo1iYn1Z37O",
    // handsontable: "8ac35-96084-14910-b4330-8181b",
    handsontable: "f39f4-23ebd-cb875-a010e-d0f2b",
    mauticURL: "https://mautic.nucamp.co",
    googleTagManagerId: "GTM-TPMG76G",
    enableGRDP: true,
    chatProvider: "crisp",
    reCAPTCHA: "6Lf6a-wgAAAAAEwDHCUY2Ht_zhT55iHIw2mWFVWk",
    crispChatWebsiteID: "f2d1e610-d3c4-4113-937e-4f8f261feba1"
}
