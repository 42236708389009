import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { UsersService } from '../../services/users/users.service';
import { NavigateService } from '../../services/navigate/navigate.service';
import { urlFix } from '../../shared/routines';
import { WindowrefService } from '../../services/windowref/windowref.service';
import { BrowsecommunitiesComponent } from '../../components/browsecommunities/browsecommunities.component';
import { CommunityService } from 'src/app/services/community/community.service';


/**
 * Generated class for the BrowseCommunitiesPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */


@Component({
    styleUrls: ['./browse-communities.page.scss'],
    selector: 'page-browse-communities',
    templateUrl: 'browse-communities.page.html',
    standalone: true,
    imports: [IonicModule, BrowsecommunitiesComponent],
})
export class BrowseCommunitiesPage implements OnInit{
  @ViewChildren("browsecommunities") BrowsecommunitiesComponents: QueryList<any>;
  pagename= "BrowseCommunitiesPage";
  @Input() postalcode: string;
  @Input() geolocation: any;
  @Input() selectonly: boolean;
  allcommunities: boolean;

  constructor(
    private modalCtrl: ModalController,
    private userdb: UsersService,
    private windowRef: WindowrefService,
    private communityService: CommunityService,
    private nucampnav: NavigateService) {  }

  ionViewWillLeave(){
    // console.log("destroy browsecommunities");
    this.BrowsecommunitiesComponents.forEach(comp => comp.ionViewWillLeave());
  }

  ngOnInit() {
    this.allcommunities = (this.windowRef.queryParamMap('allcommunities') === "true");
  }
  
  dismiss(data?) {
    // console.log("Passing data back: " + data);
    if (data) {
      this.modalCtrl.dismiss(data);
    } 
    else {
      this.modalCtrl.dismiss();
    }
  }

  async openCommunity(community){
    const emitCom = await this.communityService.avoidTXPA(community);
    console.log("openCommunity emitCom", community, emitCom);
    if (!this.selectonly) {
      if (this.userdb.loggedUser) {
        await this.userdb.updateUserPartial(
            this.userdb.loggedUser.uid, 
            {communityId: emitCom.id, communityUrlId: emitCom.urlId, location: emitCom.name, state: emitCom.state}
        );
      }
      await this.modalCtrl.dismiss()
        .then(() => this.nucampnav.openPage(`/community/${emitCom.state.toLowerCase()}/${urlFix(emitCom.displayName)}`))
        .catch(() => this.nucampnav.openPage(`/community/${emitCom.state.toLowerCase()}/${urlFix(emitCom.displayName)}`))
        // console.log(this.userdb.loggedUser);
    } else {
      this.modalCtrl.dismiss(emitCom);
    }
  }
}
