import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { CommunityService } from 'src/app/services/community/community.service';
import { UsersService } from 'src/app/services/users/users.service';
import { WindowrefService } from 'src/app/services/windowref/windowref.service';
import { User } from 'src/app/shared/datamodel';
import { isEqual } from 'lodash';
import { dayMapping, isDST, isDSTPeriodOfYear } from 'src/app/shared/routines';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { NgIf, NgClass, NgTemplateOutlet, NgFor, LowerCasePipe } from '@angular/common';
moment.extend(utc);
moment.extend(timezone);


/**
 * Generated class for the PillarsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
    styleUrls: ['./workshopoptions.scss'],
    selector: 'workshopoptions',
    templateUrl: 'workshopoptions.html',
    standalone: true,
    imports: [NgIf, IonicModule, NgClass, NgTemplateOutlet, NgFor, FontAwesomeModule, LowerCasePipe]
})
export class WorkshopoptionsComponent implements OnInit, OnDestroy {
  @Input() bootcamp;
  @Input() browsecommunities;
  @Input() completebootcamp;
  @Output() updateWorkshopDay: EventEmitter<any> = new EventEmitter();
  @Output() selectCommunity: EventEmitter<any> = new EventEmitter();
  onlineworkshops;
  selectedworkshop;
  customertz;
  customertzdisplay;
  loading = true;
  showSelection = true;
  user$: Subscription;
  faCheck = faCheck;

  fontSize = "16px";

  constructor(
    private communitysvc: CommunityService,
    public windowRef: WindowrefService,
    private userdb: UsersService
  ) { }

  async ngOnInit() {
    this.user$ = this.userdb.user
    .pipe(distinctUntilChanged((previous: User, current: User) => {
      if (!previous?.preferedworkshopgeolocation && current?.preferedworkshopgeolocation) return false;
      else if (previous?.preferedworkshopgeolocation && current?.preferedworkshopgeolocation) {
        return isEqual(previous.preferedworkshopgeolocation, current.preferedworkshopgeolocation);
      }
      else return true;
    }),
    map((user) => {
      if (user?.preferedworkshopgeolocation) this.createWorkshopOptions(user.preferedworkshopgeolocation);
      else this.createWorkshopOptions();
    }))
    .subscribe()
  }

  ngOnDestroy(): void {
    if (this.user$) this.user$.unsubscribe();
    // console.log("DESTROY")
  }

  hourFormat() {
      const format = ['en-US', 'en-GB', 'en-CA', 'en-AU'].includes(this.windowRef.nativeWindow.navigator.language) ? "12-hour" : "24-hour"
      // console.log("hourFormat");
      return format;
  }

  showHideSelection() {
    this.showSelection = !this.showSelection;
  }
  
  createWorkshopOptions(preferedworkshopgeolocation?) {
    // let workshopsdays = ["Saturday"];
    // if ((this.bootcamp && this.bootcamp?.bootcamp?.id === "HT" && this.bootcamp.startdate && moment(this.bootcamp.startdate.toDate()).isAfter(moment("20230326"))) ||
    //     (this.bootcamp && this.bootcamp?.bootcamp?.id !== "HT" && this.bootcamp.startdate && moment(this.bootcamp.startdate.toDate()).isAfter(moment("20230501"))) ||
    //       !this.bootcamp) {
    //   workshopsdays = ["Saturday", "Sunday"];
    // }
    const workshopsdays = ["Saturday", "Sunday"];

    return this.communitysvc.getWorkshopOptions()
      .then(result => {
        this.loading = false;
        if (!this.bootcamp) {
          this.bootcamp = { name: 'Beginner' };
          this.fontSize = "24px";
        }
        this.customertz = moment().utcOffset() / 60;
        const utcOffset = isDST(moment().toDate()) ? 1 : 0;
        console.log("isDST1", utcOffset);
        if (isDST(moment().toDate())) {
          console.log("isDST2", utcOffset);
        }
        if (this.customertz > 0) {
          this.customertzdisplay = `UTC+${this.customertz.toString()}`;
        } else if (Math.abs(this.customertz) === 0) {
          this.customertzdisplay = "UTC";
        } else {
          this.customertzdisplay = `UTC${this.customertz.toString()}`;
        }
        let i = 1;
        this.onlineworkshops = workshopsdays
          .map((workshopday) => {
            const timeslots = result
              .filter((community) => {
                // Closing Asia/Seoul
                if (community.geolocation.timezoneId === "Asia/Seoul") {
                  return false;
                // Not showing Flex
                } else if (community.id === "FLEX") {
                  return false;
                // Closing ONCE on Sundays
                } else if (community.geolocation.timezoneId === "America/Chicago" && workshopday === "Sunday") {
                  return false;
                } else {
                  return true;
                }
              })
              .map((community) => {
                let hourdifferences = community.geolocation.utcRawOffset - this.customertz + utcOffset;
                if (this.customertz === -10 && community.geolocation.utcRawOffset !== 9  && isDSTPeriodOfYear(moment().toDate())) hourdifferences = hourdifferences + 1;
                if (utcOffset && community.geolocation.utcRawOffset === 9) hourdifferences = hourdifferences - utcOffset;
                let starttime = 9 - hourdifferences;
                let endtime = 13 - hourdifferences;
                
                let day = workshopday;
                if (workshopday === "Saturday") {
                  if (starttime < 0) day = "Friday";
                  if (starttime >= 24) day = "Sunday";
                } else if (workshopday === "Sunday") {
                  if (starttime < 0) day = "Saturday";
                  if (starttime >= 24) day = "Monday";
                }
                
                if (starttime > 24) starttime = starttime - 24;
                if (endtime > 24) endtime = endtime - 24;
                if (starttime < 0) starttime = 24 + starttime;
                if (endtime < 0) endtime = 24 + endtime;

                let timeslot = "Morning";
                if (starttime >= 12 && starttime <= 15) timeslot = "Afternoon";
                if (starttime > 15 && starttime <= 19) timeslot = "Evening";
                if (starttime > 19 || endtime <= 9) timeslot = "Night";
                if (starttime === 24) starttime = 0;

                const minutes = ((60 * (starttime - Math.floor(starttime))) + "00").slice(0,2);
                let starttimeformatted;
                let endtimeformatted;
                if (this.hourFormat() === "12-hour") {
                  if (Math.floor(starttime) === 0 && minutes !== "00") {
                    starttimeformatted = "12:" + minutes + "am";
                  } else if (Math.floor(starttime) === 0 && minutes === "00") {
                    starttimeformatted = "12am";
                  } else if (Math.floor(starttime) === 12 && minutes !== "00") {
                    starttimeformatted = "12:" + minutes + "pm";
                  } else if (Math.floor(starttime) === 12 && minutes === "00") {
                    starttimeformatted = "12pm";
                  } else if (starttime > 12 && minutes !== "00") {
                    starttimeformatted = Math.floor(starttime - 12).toString() + ":" + minutes + "pm";
                  } else if (starttime > 12 && minutes === "00") {
                    starttimeformatted = Math.floor(starttime - 12).toString() + "pm";
                  } else if (starttime < 12 && minutes !== "00") {
                    starttimeformatted = Math.floor(starttime).toString() + ":" + minutes + "am";
                  } else if (starttime < 12 && minutes === "00") {
                    starttimeformatted = Math.floor(starttime).toString() + "am";
                  }
                  if (Math.floor(endtime) === 0 && minutes !== "00") {
                    endtimeformatted = "12:" + minutes + "am";
                  } else if (Math.floor(endtime) === 0 && minutes === "00") {
                    endtimeformatted = "12am";
                  } else if (Math.floor(endtime) === 12 && minutes !== "00") {
                    endtimeformatted = "12:" + minutes + "pm";
                  } else if (Math.floor(endtime) === 12 && minutes === "00") {
                    endtimeformatted = "12pm";
                  } else if (endtime > 12 && minutes !== "00") {
                    endtimeformatted = Math.floor(endtime - 12).toString() + ":" + minutes + "pm";
                  } else if (endtime > 12 && minutes === "00") {
                    endtimeformatted = Math.floor(endtime - 12).toString() + "pm";
                  } else if (endtime < 12 && minutes !== "00") {
                    endtimeformatted = Math.floor(endtime).toString() + ":" + minutes + "am";
                  } else if (endtime < 12 && minutes === "00") {
                    endtimeformatted = Math.floor(endtime).toString() + "am";
                  } 
                } else {
                  starttimeformatted = Math.floor(starttime).toString() + ":" + minutes;
                  endtimeformatted = Math.floor(endtime).toString() + ":" + minutes;
                }
                const preferredWorkshopSlot = {
                  communityid: community.id,
                  geolocation: community.geolocation, 
                  selectedworkshop: false,
                  index: i,
                  timeslot: {
                    starttime: starttimeformatted,
                    endtime: endtimeformatted,
                    days: day,
                    timeslot,
                    bootcampday: workshopday
                  }
                }
                if (this.browsecommunities) {
                  preferredWorkshopSlot["community"] = community;
                }

                if (this.bootcamp?.bootcamp?.cycle) {
                  let cohortIndex = this.bootcamp.bootcamp.cycle.slice(0,2);
                  if (workshopday === "Sunday") {
                    cohortIndex = "SU";
                  }
                  preferredWorkshopSlot["bootcamp"] = {
                    id: this.bootcamp.bootcamp.id,
                    cycleindex: `${cohortIndex}${this.bootcamp.bootcamp.cycle.slice(2,4)}`,
                    index: this.bootcamp.bootcamp.index,
                  }
                }
                
                if (preferedworkshopgeolocation &&
                    preferedworkshopgeolocation.utcRawOffset === preferredWorkshopSlot.geolocation.utcRawOffset && this.userdb.loggedUser.preferedworkshopgeolocation.bootcampday === workshopday) {
                  this.updateWorkshopDay.emit(preferredWorkshopSlot);
                  preferredWorkshopSlot.selectedworkshop = true;
                  this.selectedworkshop = preferredWorkshopSlot;
                } else if (!preferedworkshopgeolocation && this.bootcamp?.workshops && this.bootcamp?.community && this.bootcamp.workshops.days === preferredWorkshopSlot.timeslot.days && this.bootcamp.community.community.geolocation.utcRawOffset === preferredWorkshopSlot.geolocation.utcRawOffset) {
                  this.updateWorkshopDay.emit(preferredWorkshopSlot);
                  preferredWorkshopSlot.selectedworkshop = true;
                  this.selectedworkshop = preferredWorkshopSlot;
                }
                i++;
                return preferredWorkshopSlot;
              });
            return {workshopday, timeslots};
          })
          // .reduce((acc, cur) => {
          //   cur.forEach((timeslot) => {
          //     acc.push(timeslot);
          //   })
          //   return acc;
          // }, [])

        const isThereOnlyOneOption = this.onlineworkshops.reduce((acc, cur) => {
          cur.timeslots.forEach((timeslot) => acc.push({workshopday: cur.workshopday, timeslot: timeslot}));
          return acc;
        },[]);
        if (isThereOnlyOneOption.length === 1) {
          this.selectWorkshopTimeZone(isThereOnlyOneOption[0].workshopday, isThereOnlyOneOption[0].timeslot);
        }
        if (!this.onlineworkshops.find((day) => day.timeslots.find((workshop) => workshop.selectedworkshop))) {
          this.onlineworkshops = this.onlineworkshops
            .map((wkday) => {
              wkday.timeslots = wkday.timeslots.map((workshop) => {
                if (this.bootcamp?.community && this.bootcamp?.workshops && workshop.geolocation.utcRawOffset === this.bootcamp.community.community.geolocation.utcRawOffset && wkday.workshopday === this.bootcamp.workshops.days) {
                  this.updateWorkshopDay.emit(workshop);
                  workshop.selectedworkshop = true;
                  this.selectedworkshop = workshop;
                }
                else workshop.selectedworkshop = false;
                return workshop;
              })
              return wkday;
            })
        }
        if (this.onlineworkshops.find((day) => day.timeslots.find((workshop) => workshop.days !== "Saturday" && workshop.days !== "Sunday"))) {
          let i = 1;
          this.onlineworkshops = this.onlineworkshops
            .reduce((acc, cur) => {
              cur.timeslots.forEach((times) => {
                const findDay = acc.findIndex((wkd) => wkd.workshopday === times.timeslot.days);
                if (findDay === -1) {
                  acc.push({workshopday: times.timeslot.days, timeslots: [times]});
                } else {
                  acc[findDay].timeslots.push(times);
                }
              })
              return acc;
            }, [])
            .sort((a, b) => {
              const workshopDays = dayMapping;
              workshopDays.Sunday = 7;
              return workshopDays[a.workshopday] > workshopDays[b.workshopday] ? 1 : -1
            })
            .map((day) => {
              day.timeslots = day.timeslots.map((times) => {
                times.index = i;
                i++;
                return times;
              });
              return day;
            })
        }
        console.log("this.onlineworkshops", this.onlineworkshops);
      })
  }

  // async ngOnChanges() {
  //   console.log("Workshop Changes")
  //   await this.createWorkshopOptions();
  // }


  selectWorkshopTimeZone(day, selection) {
    if (!this.browsecommunities) {
      console.log("this.updateWorkshopDay", this.updateWorkshopDay);
      this.updateWorkshopDay.emit(selection);
      this.onlineworkshops = this.onlineworkshops
        .map((wkday) => {
          wkday.timeslots = wkday.timeslots.map((workshop) => {
            if (selection.index === workshop.index && wkday.workshopday === day) {
              workshop.selectedworkshop = true;
              this.selectedworkshop = workshop;
            }
            else workshop.selectedworkshop = false;
            return workshop;
          })
          return wkday;
        })
    } else {
      console.log("this.selectCommunity", this.selectCommunity);
      console.log(selection);
      this.selectCommunity.emit(selection.community);
    }
  }
}
