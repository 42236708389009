import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, AfterViewInit } from '@angular/core';
import { CommunityService } from '../../services/community/community.service';
import { Community } from '../../shared/datamodel';
import { map } from 'rxjs/operators';
import { convertCase } from '../../shared/routines';
import { GoogleapiService } from '../../services/googleapi/googleapi.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { WindowrefService } from 'src/app/services/windowref/windowref.service';
import { NucampanalyticsService } from 'src/app/services/nucampanalytics/nucampanalytics.service';
import { WorkshopoptionsComponent } from '../workshopoptions/workshopoptions.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { NgIf, NgTemplateOutlet, NgFor, NgClass } from '@angular/common';

/**
 * Generated class for the BrowsecommunitiesComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
    styleUrls: ['./browsecommunities.scss'],
    selector: 'browsecommunities',
    templateUrl: 'browsecommunities.html',
    standalone: true,
    imports: [NgIf, IonicModule, FontAwesomeModule, NgTemplateOutlet, NgFor, NgClass, WorkshopoptionsComponent]
})
export class BrowsecommunitiesComponent implements OnChanges, AfterViewInit{
  @Input() postalcode: any;
  @Input() geolocation: any;
  @Input() community: any;
  @Input() allcommunities: boolean;
  @Input() communitypage: boolean;
  @Input() hideSelector: boolean;
  @Input() numbercommunities;
  @Input() selectonly: boolean;
  @Output() selCommunityEvent: EventEmitter<any> = new EventEmitter();
  @Output() scrollTo: EventEmitter<any> = new EventEmitter();
  @ViewChild("comlist") comlist: ElementRef;
  @ViewChild("searchbox") searchbox: ElementRef;
  @ViewChild("searchboxcommunity") searchboxcommunity;

  
  communities: Community[];
  onlineCommunities: Community[];
  orginalCommunities: Community[];
  subArray: Subscription[] = [];
  searchposition;
  resultposition;
  loading = false;
  numbercommunitiesdisplayed = 8;
  faMapMarkerAlt = faMapMarkerAlt;
  showWorkShopOptions = false;
  
  constructor(
    private comdb: CommunityService,
    private google: GoogleapiService,
    private analytics: NucampanalyticsService,
    public winRef: WindowrefService
    ) {
  }

  ngAfterViewInit() {
    this.resultposition = this.comlist.nativeElement.offsetTop - 50;
    if (this.postalcode !== "ONLINE") {
      this.subArray.push(this.comdb.getOnlineRegionalCommunities().subscribe(coms => {
        // console.log("Fetching online communities");
        this.onlineCommunities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
      }))
    }
  }

  ionViewWillLeave() {
    // console.log("Destroying Browse Communities")
    this.subArray.forEach(sub => sub.unsubscribe());
  }

  regionalCommunities(communities) {
    // console.log("regionalCommunities", communities);
    if (communities) return communities.some(community => community.type === 'online-regional');
    else return false;
  }

  setFocusOnInput() {
    console.log("Setting Focus", this.searchboxcommunity);
    if (!this.hideSelector && !this.communitypage && this.searchboxcommunity) {
      this.searchboxcommunity.setFocus();
    }
  }

  toggleShowWorkshopOptions() {
    this.showWorkShopOptions = !this.showWorkShopOptions;
  }

  async ngOnChanges() {
    if (this.numbercommunities && this.numbercommunities > 0) this.numbercommunitiesdisplayed = parseInt(this.numbercommunities);
    console.log("Browse Communities", this.geolocation);
    if (!this.geolocation && !this.community && !this.postalcode) {
      console.log("BrowsecommunitiesComponent: Setting geolocation from analytics");
      this.geolocation = await this.analytics.getGeoLocation().then(result => {
        if (result?.location) return result.location;
        else return null;
      });
    }
    if (this.community && this.postalcode === this.community.state) {
      this.loading = true;
      firstValueFrom(this.comdb.searchCommunitiesByState(this.community.state, this.community, this.numbercommunitiesdisplayed + 2))
      .then(coms => {
        this.communities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.orginalCommunities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.loading = false;
        this.setFocusOnInput();
      })
    } else if (this.geolocation && this.geolocation.lat && this.geolocation.lng) {
      this.loading = true;
      this.comdb.getTopCommunitiesByZipCode(this.geolocation, this.numbercommunitiesdisplayed + 2, "community")
      .then(coms => {
        this.communities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.orginalCommunities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.loading = false;
        this.setFocusOnInput();
      })
    } else if (this.postalcode && this.postalcode != "" && this.postalcode !== "ONLINE") {
      this.loading = true;
      this.subArray.push(
        this.fetchZipCodeComs(this.postalcode)
          .subscribe(result => {
            if (result) {
              result.then(coms => {
                this.communities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
                this.orginalCommunities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
                this.loading = false;
                this.setFocusOnInput();
              })
            }
            else {
              this.loading = false;
              this.setFocusOnInput();
            }
          })
      )
    } else if (this.postalcode === "ONLINE" && !this.allcommunities) {
      this.loading = true;
      this.subArray.push(this.comdb.getOnlineRegionalCommunities().subscribe(coms => {
        this.communities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.orginalCommunities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.loading = false;
        this.setFocusOnInput();
        // console.log(this.communities);
      }))
    } else if (this.allcommunities === true) {
      this.loading = true;
      firstValueFrom(this.comdb.getAllCommunities()).then(coms => {
        console.log("All communities", coms);
        this.communities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.orginalCommunities = coms.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
        this.loading = false;
      })
    }
  }

  getCommunityByGeo(){
    this.loading = true;
    this.subArray.push(this.comdb.getCommunitybyGeoLocation(this.numbercommunitiesdisplayed).subscribe(result => {
      if (result) {
        result.then(com => {
          this.communities = com.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
          this.loading = false;
          this.scrollToSelection(this.resultposition);
        });
      }
      else this.loading = false;
    }))
  }

  selectCommunity(arg) {
    // console.log(arg);
    this.selCommunityEvent.emit(arg)
  }

  scrollToSelection(position) {
    // console.log("Emit:", position);
    this.scrollTo.emit(position);
  }

  citySearch($event) {
      // console.log($event);
      const q = $event.target.value;
      // console.log("Search Box:", this.searchbox);
      // this.scrollToSelection(this.searchbox.nativeElement.offsetTop)
      if (q && q !="") {
          this.loading = true;
          this.subArray.push(this.comdb.searchCommunities(q).subscribe((coms:Community[]) => {
            this.communities = coms.slice(0,this.numbercommunitiesdisplayed).map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
            // console.log("Comlist", this.comlist.nativeElement);
            this.loading = false;
            this.scrollToSelection(this.resultposition - 50);
        }))
      } else {
        this.communities = this.orginalCommunities;
        this.scrollToSelection(this.searchposition);
        this.loading = false;
      }
  }

  fetchZipCodeComs(q) {
    // console.log("incoming zipcode", q);
    return this.google.zipcodeGeoCode(q, "US").pipe(
      map(result => {
        // console.log("outgoing result", result);
        if (result) {
          return this.comdb.getTopCommunitiesByZipCode(result, this.numbercommunitiesdisplayed, "community") as any;
        }
        else return null;
      }))
  }

  zipCodeSearch($event) {
      const q = $event.target.value;
      if (q && q !="" && q.length === 5) {
        this.loading = true;
        this.subArray.push(this.fetchZipCodeComs(q)
          .subscribe(result => {
            if (result) {
              result.then(com => {
                this.communities = com.map(com => ({...com, communityPicture: this.comdb.selectCommunityPicture(com)}));
                this.loading = false;
                this.scrollToSelection(this.resultposition);
              });
            }
            else this.loading = false;
          }))
        }
      else {
        this.communities = this.orginalCommunities;
        this.loading = false;
        this.scrollToSelection(this.searchposition);
      }    
  }
}
