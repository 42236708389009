<ion-app>
  <ng-container *ngIf="windowRef.isBrowserRendering()">
    <ion-menu side="start" menuId="hamburger-menu" contentId="main">
      <ion-header class="ion-no-padding" no-border>
        <ion-row style="padding: 20px 0px 0px 16px; margin: auto">
          <ion-col size="12" class="ion-text-left hoverover hamburger">
            <div (click)="toggleMenu()" style="display: inline-flex;">
              <ion-icon name="menu-outline" style="font-size: 22px;padding-right: 4px"></ion-icon>
              <!-- <div>MENU</div> -->
            </div>
          </ion-col>
        </ion-row>
      </ion-header>
      <ng-container *ngTemplateOutlet="contentmenu"></ng-container>
    </ion-menu>
  </ng-container>
  <ng-container *ngIf="!windowRef.isBrowserRendering()">
    <ng-container *ngTemplateOutlet="contentmenu"></ng-container>
  </ng-container>

  <ng-template #contentmenu>
    <ion-content>
      <ion-list>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="closeMenu()">
          <a routerDirection="root" routerLink="/" style="color: unset;">Home</a>
        </ion-item>
        <ion-item mode="md" *ngIf="!currentCommunity" class="hoverover" lines="none" color='#f3f4f5'
          (click)="openCommunity()">My Community</ion-item>
        <ion-item mode="md" *ngIf="currentCommunity && currentCommunity.displayName" class="hoverover" lines="none"
          color='#f3f4f5' (click)="openCommunity()">{{currentCommunity.displayName}}</ion-item>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="openBootcamps()">Bootcamps
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-item>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="openFinancing()">Financing
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-item>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="openCareerServices()">Job Outcomes
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-item>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="openBusiness()">Business
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-item>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="closeMenu()"><a routerDirection="root" routerLink="frequentlyaskedquestions" style="color: unset;">F.A.Q.</a>
        </ion-item>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="closeMenu()"><a routerDirection="root" routerLink="blogs" style="color: unset;">Blog</a></ion-item>
        <ion-item mode="md" class="hoverover" lines="none" color='#f3f4f5' (click)="openMore()">More<ion-icon
            name="chevron-forward-outline"></ion-icon>
        </ion-item>
        <ion-item *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && userProvider.loggedUser.roles.admin"
          class="hoverover" lines="none" color='#f3f4f5' (click)="openAdmin()">Admin<ion-icon
            name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>

  <div [style.display]="showBootcampMenu ? '' : 'none'" class="dropdown-content" style="top: 145px" (click)="closeMenu()">
    <a routerDirection="root" routerLink="/bootcamp-overview/cybersecurity-ethicalhacking-network-defense">
      <div class="img-space"><img src="assets/imgs/bootcamps/cyberfundamentals.svg" alt="Cybersecurity Fundamentals icon" /></div>
      <span>Cybersecurity Fundamentals</span></a>
    <a routerDirection="root" routerLink="/bootcamp-overview/web-development-fundamentals">
        <div class="img-space"><img src="assets/imgs/bootcamps/beginner.svg" alt="Web Fundamentals icon" /></div>
        <span>Web Development Fundamentals</span></a>
    <a routerDirection="root" routerLink="/bootcamp-overview/back-end-sql-devops-python">
        <div class="img-space"><img src="assets/imgs/bootcamps/backend.svg" alt="Back End SQL and DevOps with Python" /></div>
        <span>Backend, SQL, and DevOps with Python</span></a>
    <a routerDirection="root" routerLink="/bootcamp-overview/front-end-web-development">
        <div class="img-space"><img src="assets/imgs/bootcamps/intermediate.svg" alt="Front End Web Mobile Development icon" /></div>
        <span>Front End Web + Mobile Development</span></a>
    <a routerDirection="root" routerLink="/bootcamp-overview/full-stack-web-mobile-development">
        <div class="img-space"><img src="assets/imgs/bootcamps/advanced.svg" alt="Full Stack Web Development icon" /></div>
        <span>Full Stack Web + Mobile Development</span></a>
    <a routerDirection="root" routerLink="/bootcamp-overview/job-hunting">
        <div class="img-space"><img src="assets/imgs/bootcamps/careerservices.svg" alt="Job Hunting icon" /></div>
        <span>Job Hunting</span></a>
    <a routerDirection="root" routerLink="/complete-bootcamp/software-engineering">
        <div class="img-space"><img src="assets/imgs/bootcamps/illustration-complete-computer2.svg" alt="Complete Software Engineering Bootcamp Path" /></div>
        <span>Complete Software Engineering Bootcamp Path</span></a>  
  </div>

  <div [style.display]="showFinancingMenu ? '' : 'none'" class="dropdown-content" style="top: 185px" (click)="closeMenu()">
    <a routerDirection="root" routerLink="/financing"><span>Fair Student Agreement</span></a>
    <a *ngIf="!currentCommunity || (currentCommunity && currentCommunity.state !== 'PA')" routerDirection="root" routerLink="/scholarships"><span>Scholarships</span></a>
  </div>

  <div [style.display]="showCareerServicesMenu ? '' : 'none'" class="dropdown-content" style="top: 225px" (click)="closeMenu()">
    <a routerDirection="root" routerLink="/careerservices"><span>Career Services</span></a>
    <a routerDirection="root" routerLink="/newcareers"><span>Graduates Stories</span></a>
    <a routerDirection="root" routerLink="/graduates/projects"><span>Graduates Projects</span></a>
  </div>

  <div [style.display]="showBusinessMenu ? '' : 'none'" class="dropdown-content" style="top: 250px" (click)="closeMenu()">
    <a routerDirection="root" routerLink="/business"><span>Nucamp for Business</span></a>
    <a routerDirection="root" routerLink="/business/jobboard"><span>Hire a Grad!</span></a>
    <a routerDirection="root" routerLink="/affiliate"><span>Affilate Program</span></a>
  </div>

  <div [style.display]="showMoreMenu ? '' : 'none'" class="dropdown-content" style="top: 310px" (click)="closeMenu()">
    <a routerDirection="root" routerLink="/free-workshops"><span>Free Workshops</span></a>
    <a routerDirection="root" routerLink="/number1"><span>Number 1</span></a>
    <a routerDirection="root" routerLink="/business/viewjobs"><span
        *ngIf="userProvider.loggedUser && ((userProvider.loggedUser.roles && userProvider.loggedUser.roles.admin) || (userProvider.loggedUser.graduate && userProvider.loggedUser.graduate.graduate))">
        Job Board</span></a>
    <a routerDirection="root" routerLink="/instructor"><span
        *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.instructor || userProvider.loggedUser.roles.admin || userProvider.loggedUser.roles.instructormanager)">
        Instructor Dashboard</span></a>
    <a routerDirection="root" routerLink="/admin/customernotes"><span
        *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.advisor || userProvider.loggedUser.roles.admin)">
        Customer Notes</span></a>
    <a routerDirection="root" routerLink="/admin/instructorcoaching"><span
        *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.instructorcoach || userProvider.loggedUser.roles.admin)">
        Instructor Coaching</span></a>
    <!-- <a routerDirection="root" routerLink="/meetus"><span>Meet Us</span></a> -->
    <!-- <a routerDirection="root" routerLink="/affiliate"><span>Affilate Program</span></a> -->
    <!-- <a routerDirection="root" routerLink="/aboutus"><span>About</span></a> -->
    <span (click)="openJobsWindow()">Jobs</span>
    <a href="https://mautic.nucamp.co/job-application"><span style="display:none">Jobs</span></a>
  </div>

  <div *ngIf="showAdminMenu" class="dropdown-content" style="top: 350px" (click)="closeMenu()">
    <!-- <a *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.admin || userProvider.loggedUser.roles.communitymanager || userProvider.loggedUser.roles.instructormanager)"
      routerLink="/admin/cohorts">
      <span>Admin Cohorts</span>
    </a> -->
    <a *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && userProvider.loggedUser.roles.admin"
      routerDirection="root"
      routerLink="/admin/rollovers">
      <span>Rollover Cohorts</span>
    </a>
    <a *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.admin || userProvider.loggedUser.roles.schedulemanager)"
      routerDirection="root"
      routerLink="/admin/scheduling">
      <span>Scheduling</span>
    </a>
    <a *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.admin || userProvider.loggedUser.roles.workshopmanager)"
      routerDirection="root"
      routerLink="/admin/workshops">
      <span>Workshops</span>
    </a>
    <a *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.admin || userProvider.loggedUser.roles.instructormanager)"
      routerDirection="root"
      routerLink="/admin/instructors">
      <span>Instructor List</span>
    </a>
    <a *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.admin || userProvider.loggedUser.roles.schedulemanager || userProvider.loggedUser.roles.saturdaymanager || userProvider.loggedUser.roles.instructormanager)"
      routerDirection="root"
      routerLink="/admin/monitoring">
      <span>Monitor Bootcamps</span>
    </a>
    <a *ngIf="userProvider.loggedUser && userProvider.loggedUser.roles && (userProvider.loggedUser.roles.admin || userProvider.loggedUser.roles.advisor)"
      routerDirection="root"
      routerLink="/admin/advisormeetings">
    <span>Advisor Meetings</span>
  </a>
  </div>

  <ion-router-outlet id="main" animated="true"></ion-router-outlet>

  <!-- I indicate that a router module is being loaded asynchronously. -->
  <!-- <div *ngIf="isShowingRouteLoadIndicator" class="router-load-indicator">
    loading...
  </div> -->
</ion-app>

