import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { GeoPoint, DocumentReference } from '@angular/fire/firestore';
import { Observable, combineLatest, of, defer, firstValueFrom } from 'rxjs';
import { tap, take, map, switchMap, catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import firebase from 'firebase/compat/app';

  // Custom Types

  type CollectionPredicate<T>   = string |  AngularFirestoreCollection<T>;
  type DocPredicate<T> = string |  AngularFirestoreDocument<T>;

/*
  Generated class for the FirestoreService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({providedIn: 'root'})
export class FirestoreService {
  readonly api = `${environment.functionsURL}/app`;
  readonly aiapi = `${environment.functionsURL}/ai`;

  constructor(
    public afs: AngularFirestore,
    private http: HttpClient,
    ) {
  }
  
  private handleError(error: HttpErrorResponse) {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error('An error occurred:', error);
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    // return an observable with a user-facing error message
    return of(error);
  }
  
  /// **************
  /// Call the Nucamp App Backend
  /// **************
  public get<T>(path, params?, retrycall?): Observable<T> {
    if (params) {
      const hParams = new HttpParams({
        fromObject: params
      })
      if (retrycall) {
        return this.http.get(`${this.api}${path}`, {params: hParams})
        .pipe(
          retry(2),
          catchError(this.handleError)) as Observable<T>;
      } else {
        return this.http.get(`${this.api}${path}`, {params: hParams})
        .pipe(
          catchError(this.handleError)) as Observable<T>;
      }
    } else {
      return this.http.get(`${this.api}${path}`) as Observable<T>;
    }
  }

  public getAI<T>(path, params?, retrycall?): Observable<T> {
    if (params) {
      const hParams = new HttpParams({
        fromObject: params
      })
      if (retrycall) {
        return this.http.get(`${this.aiapi}${path}`, {params: hParams})
        .pipe(
          retry(2),
          catchError(this.handleError)) as Observable<T>;
      } else {
        return this.http.get(`${this.aiapi}${path}`, {params: hParams})
        .pipe(
          catchError(this.handleError)) as Observable<T>;
      }
    } else {
      return this.http.get(`${this.aiapi}${path}`) as Observable<T>;
    }
  }

  public fetch<T>(path, params?,  retrycall?): Observable<T> {
    if (params) {
      const hParams = new HttpParams({
        fromObject: params
      })
      if (retrycall) {
        return this.http.get(path, {headers: {'Access-Control-Allow-Origin': '*'}, params: hParams})
        .pipe(
          retry(2),
          catchError(this.handleError)) as Observable<T>;
      } else {
        return this.http.get(path, {headers: {'Access-Control-Allow-Origin': '*'}, params: hParams})
        .pipe(
          catchError(this.handleError)) as Observable<T>;
      }
    } else {
      return this.http.get(path, {headers: {'Access-Control-Allow-Origin': '*'}}) as Observable<T>;
    }
  }

  public formPost(path, data){
    let formData = new HttpParams();
    // console.log('params :' + params);
    for (const mparam in data) {
      if (mparam) {
        formData = formData.append(mparam, data[mparam]);
      }
    }
    // console.log('formData = ' + formData)
    // PAUSING POSTING FORMS FOR DEV PURPOSES
    return firstValueFrom(this.http.post(path, formData))
      .catch(err => console.log(err));
  }
  
  public postAI<T>(path, data): Observable<T> {
    return this.http.post(`${this.aiapi}${path}`, data)
    .pipe(
      // retry(2), 
      catchError(this.handleError)) as Observable<T>;
  }

  public patchAI<T>(path, data): Observable<T> {
    return this.http.patch(`${this.aiapi}${path}`, data)
    .pipe(
      // retry(2), 
      catchError(this.handleError)) as Observable<T>;
  }

  public post<T>(path, data): Observable<T> {
    return this.http.post(`${this.api}${path}`, data)
    .pipe(
      // retry(2), 
      catchError(this.handleError)) as Observable<T>;
  }

  public patch<T>(path, data): Observable<T> {
    return this.http.patch(`${this.api}${path}`, data)
    .pipe(
      // retry(2), 
      catchError(this.handleError)) as Observable<T>;
  }

  /// **************
  /// Get a Reference
  /// **************

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }



  /// **************
  /// Get Data
  /// **************


  doc$<T>(ref:  DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      return doc.payload.data() as T
    }))
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(docs => {
      return docs.map(a => a.payload.doc.data()) as T[]
    }));
  }

  //with DocumentChangeType
  docWithType$<T>(ref:  DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data:any = doc.payload.data();
      const id = doc.payload.id;
      const type = doc.type;
      return {id, type, ...data};
    }))
  }

  colWithTypes$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data:any = a.payload.doc.data();
        const id = a.payload.doc.id;
        const type = a.type;
        return {id, type, ...data};
      })
    }));
  }

  /// with Ids
  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data:any = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
  }
  
  docWithIds$<T>(ref:  DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      if (doc.payload.exists) {
        const data:any = doc.payload.data();
        const id = doc.payload.id;
        return { id, ...data } as T;
      } 
    }))
  }




  /// **************
  /// Write Data
  /// **************


  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  set<T>(ref: DocPredicate<T>, data: any) {
    const timestamp = this.timestamp
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    })
  }

  update<T>(ref: DocPredicate<T>, data: any) {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp
    })
  }


  delete<T>(ref: DocPredicate<T>) {
    return this.doc(ref).delete();
  }

  add<T>(ref: CollectionPredicate<T>, data) {
    const timestamp = this.timestamp
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    })
  }


  geopoint(lat: number, lng: number) {
    return new GeoPoint(lat, lng)
  }


  /// If doc exists update, otherwise set
  upsert<T>(ref: DocPredicate<T>, data: any) {
    const doc = firstValueFrom(this.doc(ref).snapshotChanges().pipe(take(1)))

    return doc.then(snap => {
      return snap.payload.exists ? this.update(ref, data) : this.set(ref, data)
    })
  }


  /// **************
  /// Inspect Data
  /// **************


  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime()
    this.doc(ref).snapshotChanges()
        .pipe(
          take(1),
          tap(d => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Document in ${tock}ms`, d);
        }))
        .subscribe()
  }


  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime()
    this.col(ref).snapshotChanges()
      .pipe(
        take(1),
        tap(c => {
          const tock = new Date().getTime() - tick
          console.log(`Loaded Collection in ${tock}ms`, c)
        }))
        .subscribe()
  }

  /// **************
  /// Call Cloud Functions
  /// **************
  // cloudFunctions(functionName, arg) {
  //   return this.afu.functions.httpsCallable(functionName)(arg);
  // }

  /// **************
  /// Create and read doc references
  /// **************

  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>) {
    return this.doc(host).update({ [key]: this.doc(doc).ref })
  }


  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>) {
    return this.doc$(ref).pipe(map(doc => {
      for (const k of Object.keys(doc)) {
        if (doc[k] instanceof DocumentReference) {
          doc[k] = this.doc(doc[k].path)
        }
      }
      return doc
    }))
  }

  docJoin  (paths: { [key: string]: string }) {
    return source =>
    defer(() => {
      let parent;
      const keys = Object.keys(paths);

      return source.pipe(
        switchMap(data => {
          // Save the parent data state
          parent = data;

          // Map each path to an Observable
          const docs$ = keys.map(k => {
            const fullPath = `${paths[k]}/${parent[k]}`;
            return this.afs.doc(fullPath).valueChanges();
          });

          // return combineLatest, it waits for all reads to finish
          return combineLatest(docs$);
        }),
        map(arr => {
          // We now have all the associated douments
          // Reduce them to a single object based on the parent's keys
          const joins = keys.reduce((acc, cur, idx) => {
            return { ...acc, [cur]: arr[idx] };
          }, {});

          // Return the parent doc with the joined objects
          return { ...parent, ...joins };
        })
      );
    });
  }

  leftJoin (field, collection, limit = 100) {
    return source =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap(data => {
          // Clear mapping on each emitted val ;
          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array
            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = ref => ref.where(field, '==', doc[field]).limit(limit);

              reads$.push(this.afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map(joins => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap(final => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
  }

  /// **************
  /// Atomic batch example
  /// **************

  batch$() {
    return this.afs.firestore.batch();
  }

  runTransaction$<T>(updateFunction: (transaction: firebase.firestore.Transaction) => Promise<T>) {
    return this.afs.firestore.runTransaction(updateFunction);
  }

  /// Just an example, you will need to customize this method.
  atomic() {
    const batch = this.afs.firestore.batch()
    /// add your operations here

    const itemDoc = this.afs.firestore.doc('items/myCoolItem');
    const userDoc = this.afs.firestore.doc('users/userId');

    const currentTime = this.timestamp

    batch.update(itemDoc, { timestamp: currentTime });
    batch.update(userDoc, { timestamp: currentTime });

    /// commit operations
    return batch.commit()
  }
}
